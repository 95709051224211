<template>
	<div>
		<!-- <h4 class="header-title">List Quotation</h4> -->
		<vue-element-loading :active="isLoadingTable" spinner="bar-fade-scale" color="#FF6700" />
		<div class="row">
			<div class="col-lg-3">
				<b-button class="btn btn-create btn-sm" style="background: #51C1AD; border: 1px; height: 35px;"
					@click="openModalBuyingDownPayment" v-can="'CASH_ADVANCE_CREATE'">
					<i class="mdi mdi-plus mr-1"></i>
					Create Transaction
				</b-button>
			</div>
			<div class="col-lg-3" style="display: flex;justify-content: start;align-items: center;">
				<b-form-checkbox @change="hideFinishedData" :checked="false"></b-form-checkbox>
				<span>Hide Finished Cash Advance</span>
			</div>
			<div class="col-sm-12 col-md-12 col-lg-6">
				<div class="float-lg-right float-left">
					<div class="form-inline">
						<label class="mb-2 mr-sm-2">Search :</label>
						<input v-model="filterText" type="text" class="form-control form-control-sm mb-2" placeholder="Search . . ."
							@keyup="doFilter" />
						<!-- <button type="button" class="btn btn-primary mb-2 mr-sm-2" @click="doFilter">Go</button> -->
						<!-- <button type="button" class="btn btn-danger mb-2 mr-sm-2" @click="resetFilter">Reset</button> -->
					</div>
				</div>
			</div>
		</div>

		<!-- Table -->
		<div class="table-responsive">
			<vuetable ref="vuetable" :per-page="perPage" :fields="fields" :api-url="apiUrl" :api-mode="true"
				:http-options="httpOptions" :show-sort-icons="true" :append-params="moreParams" pagination-path
				class="table table-centered table-striped" data-path="data" @vuetable:loading="loadingTable"
				@vuetable:load-success="loadTableSuccess" @vuetable:load-error="loadTableFailed"
				@vuetable:pagination-data="onPaginationData">
				<template slot="actions" slot-scope="props">
					<div class="table-button-container" style="display: flex;justify-content: center;">
						<!-- <button class="btn btn-action mr-1" style="background: #0088E1;" href="javascript:void(0)" title="View Detail" onclick="showdetail('72')">
              <i class="fa fa-search" style="color: #FFF;"></i>
            </button> -->
						<button v-can="'CASH_ADVANCE_UPDATE'" class="btn btn-action mr-1" style="background: #51C1AD;"
							href="javascript:void(0)" title="View Detail" @click="editInfoBuying(props.rowData.id)">
							<img src="@assets/images/icon-edit.svg" id="edit" class="edit" border="0">
						</button>
						<button v-can="'CASH_ADVANCE_DELETE'" class="btn btn-action" style="background: #EA4C40;"
							href="javascript:void(0)" title="Delete"
							@click="deleteBuyingData(props.rowData.id, props.rowData.count_pi)">
							<img src="@assets/images/icon-delete.svg" id="edit" class="edit" border="0">
						</button>
						<button class="btn btn-action ml-1" style="background:rgba(2, 118, 160, 1);"
							title="Submit to AOL Cash Advance" @click="submitToAol(props.rowData.id)"
							v-show="companyIsAol === 1 && (props.rowData.id_aol === '0' || props.rowData.id_aol === null || props.rowData.id_aol === 0)">
							<img src="@assets/images/icon-export2.svg" id="edit" class="edit" border="0">
						</button>
						<button class="btn btn-action ml-1" style="background:#7fc2f5;" title="Submit to AOL Purchase Payment"
							@click="submitToAolPP(props.rowData.id_pp, props.rowData.id)"
							v-show="companyIsAol === 1 && (props.rowData.id_aol_pp === '0' || props.rowData.id_aol_pp === null || props.rowData.id_aol_pp === 0) && (props.rowData.id_aol !== '0' && props.rowData.id_aol !== null && props.rowData.id_aol !== 0)">
							<img src="@assets/images/icon-export2.svg" id="edit" class="edit" border="0">
						</button>
						<button class="btn btn-action ml-1" style="background:#12e683;" title="Submit to AOL Purchase Payment Tax"
							@click="submitToAolPPTax(props.rowData.id_pp_tax, props.rowData.id)"
							v-show="companyIsAol === 1 && ((parseInt(props.rowData.ppn) > 0 && props.rowData.id_currency !== 0) && (props.rowData.id_aol_pp_tax === '0' || props.rowData.id_aol_pp_tax === null || props.rowData.id_aol_pp_tax === 0)) && (props.rowData.id_aol_pp !== '0' && props.rowData.id_aol_pp !== null && props.rowData.id_aol_pp !== 0) && (props.rowData.id_aol !== '0' && props.rowData.id_aol !== null && props.rowData.id_aol !== 0)">
							<img src="@assets/images/icon-export2.svg" id="edit" class="edit" border="0">
						</button>
						<button class="btn btn-action ml-1" style="background:#F9A707;" title="Copy"
							@click="openModalBuying(props.rowData.id, props.rowData.id_vendor, props.rowData.grandTotal, props.rowData.date)"
							v-show="companyIsAol === 1 ? (props.rowData.is_finished !== 1 && (props.rowData.totalClaimed < props.rowData.grandTotal) && (props.rowData.id_aol_pp !== '0' && props.rowData.id_aol_pp !== null && props.rowData.id_aol_pp !== 0) && (props.rowData.id_aol !== '0' && props.rowData.id_aol !== null && props.rowData.id_aol !== 0) && (parseInt(props.rowData.ppn) > 0 && props.rowData.id_currency !== 0 ? (props.rowData.id_aol_pp_tax !== '0' && props.rowData.id_aol_pp_tax !== null && props.rowData.id_aol_pp_tax !== 0) : true)) : (props.rowData.is_finished !== 1 ? true : false)">
							<img src="@assets/images/icon-copy.svg" id="copy" class="edit" border="0">
						</button>
						<div v-can="'FINISHED_MULTI_BUYING_DP_ENABLE'">
							<button class="btn btn-action ml-1" style="background: #000;" href="javascript:void(0)" title="Finish"
								@click="finishDP(props.rowData.id)"
								v-show="companyIsAol === 1 ? (props.rowData.is_finished !== 1 && (props.rowData.id_aol_pp !== '0' && props.rowData.id_aol_pp !== null && props.rowData.id_aol_pp !== 0) && (props.rowData.id_aol !== '0' && props.rowData.id_aol !== null && props.rowData.id_aol !== 0) && (parseInt(props.rowData.ppn) > 0 && props.rowData.id_currency !== 0 ? (props.rowData.id_aol_pp_tax !== '0' && props.rowData.id_aol_pp_tax !== null && props.rowData.id_aol_pp_tax !== 0) : true)) : (props.rowData.is_finished !== 1 ? true : false)">
								<img src="@assets/images/complete.png" id="edit" class="edit" border="0" width="16">
							</button>
						</div>
						<b-dropdown id="dropdown-1" dropup size="sm" variant="bg-transparent" style="padding:0px !important;"
							v-show="props.rowData.id_aol !== '0' || props.rowData.companyAol === 0">
							<template v-slot:button-content style="padding:0px !important;">
								<button class="btn btn-action ml-1 btn-secondary" style="" title="Download pdf">
									<i class="fas fa-download"></i>
								</button>

							</template>
							<b-dropdown-item href="#" @click.prevent="getPdf(props.rowData)">

								Generate PDF
							</b-dropdown-item>
						</b-dropdown>
						<!-- <a
              v-can="'QUOTATION_VIEW'"
              :href="urlPdf"
              target="_blank"
              class="action-icon mr-1"
              @click="onAction('view-pdf', props.rowData, props.rowIndex)"
            >
              <i class="fas fa-download"></i>
            <a
              v-can="'QUOTATION_UPDATE'"
              href="javascript:void(0);"
              class="action-icon  mr-1"
              @click="onAction('edit-item', props.rowData, props.rowIndex)"
            >
              <i class="fas fa-edit"></i
                      >
            </a>
            <a
              v-can="'QUOTATION_DELETE'"
              href="javascript:void(0);"
              class="action-icon "
              @click="onAction('delete-item', props.rowData, props.rowIndex)"
            >
              <i class="fas fa-trash-alt"></i
                      >
            </a> -->
					</div>
				</template>
				<template slot="disc_acc_name" slot-scope="props">
					<span v-show="props.rowData.discount_pp.length === 1">{{ props.rowData.discount_pp[0] === undefined ? '' :
						props.rowData.discount_pp[0].discount_name }}</span>
					<a href="javascript:void(0);" class="action-icon  mr-1" @click="showDiscountAccounts(props.rowData)"
						style="font-size: 12px;">
						<div v-show="props.rowData.discount_pp.length > 1">
							Discount Accounts
							<svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M8.5 1C8.5 0.723857 8.27614 0.5 8 0.5L3.5 0.5C3.22386 0.5 3 0.723858 3 1C3 1.27614 3.22386 1.5 3.5 1.5L7.5 1.5L7.5 5.5C7.5 5.77614 7.72386 6 8 6C8.27614 6 8.5 5.77614 8.5 5.5L8.5 1ZM1.35355 8.35355L8.35355 1.35355L7.64645 0.646447L0.646447 7.64645L1.35355 8.35355Z"
									fill="#FFA800" />
							</svg>
						</div>
					</a>
				</template>
				<template slot="disc_acc_name_tax" slot-scope="props">
					<span v-show="props.rowData.discount_pp_tax.length === 1">{{ props.rowData.discount_pp_tax[0] === undefined ? ''
						: props.rowData.discount_pp_tax[0].discount_name }}</span>
					<a href="javascript:void(0);" class="action-icon  mr-1" @click="showDiscountAccounts(props.rowData, true)"
						style="font-size: 12px;">
						<div v-show="props.rowData.discount_pp_tax.length > 1">
							Discount Accounts
							<svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M8.5 1C8.5 0.723857 8.27614 0.5 8 0.5L3.5 0.5C3.22386 0.5 3 0.723858 3 1C3 1.27614 3.22386 1.5 3.5 1.5L7.5 1.5L7.5 5.5C7.5 5.77614 7.72386 6 8 6C8.27614 6 8.5 5.77614 8.5 5.5L8.5 1ZM1.35355 8.35355L8.35355 1.35355L7.64645 0.646447L0.646447 7.64645L1.35355 8.35355Z"
									fill="#FFA800" />
							</svg>
						</div>
					</a>
				</template>
				<template slot="counting_number" slot-scope="props">
					<span>{{ ((pageActive - 1) * perPage) + props.rowIndex + 1 }}</span>
				</template>
				<template slot="count_pi" slot-scope="props">
					<a href="javascript:void(0);" class="action-icon  mr-1"
						@click="onAction('DetailPI', props.rowData, props.rowIndex)" style="font-size: 12px;">
						<div style="color: #67C8B7;">
							{{ props.rowData.count_pi }} PI
							<svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M8.5 1C8.5 0.723857 8.27614 0.5 8 0.5L3.5 0.5C3.22386 0.5 3 0.723858 3 1C3 1.27614 3.22386 1.5 3.5 1.5L7.5 1.5L7.5 5.5C7.5 5.77614 7.72386 6 8 6C8.27614 6 8.5 5.77614 8.5 5.5L8.5 1ZM1.35355 8.35355L8.35355 1.35355L7.64645 0.646447L0.646447 7.64645L1.35355 8.35355Z"
									fill="#67C8B7" />
							</svg>
						</div>
					</a>
				</template>
				<template slot="down_payment" slot-scope="props">
					<div style="width: 70px;" v-show="props.rowData.inclusive_tax === '0' && parseFloat(props.rowData.ppn) > 0">
						<span>{{ props.rowData.symbol }}. {{ formatRupiah((parseFloat(props.rowData.down_payment) +
							(parseFloat(props.rowData.down_payment) * parseFloat(props.rowData.ppn) / 100)).toString(), '') }}</span>
					</div>
					<div style="width: 70px;" v-show="props.rowData.inclusive_tax === '1'">
						<span>{{ props.rowData.symbol }}. {{ formatRupiah(parseFloat(props.rowData.down_payment).toString(), '')
						}}</span>
					</div>
					<div style="width: 70px;" v-show="parseFloat(props.rowData.ppn) < 1">
						<span>{{ props.rowData.symbol }}. {{ formatRupiah(parseFloat(props.rowData.down_payment).toString(), '')
						}}</span>
					</div>
					<!-- <a
              href="javascript:void(0);"
              class="action-icon  mr-1"
              @click="onAction('DetailPI', props.rowData, props.rowIndex)"
              style="font-size: 12px;"
            >
            <div class="table-button-container" style="color: #67C8B7;">
              {{props.rowData.count_pi}} PI
              <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.5 1C8.5 0.723857 8.27614 0.5 8 0.5L3.5 0.5C3.22386 0.5 3 0.723858 3 1C3 1.27614 3.22386 1.5 3.5 1.5L7.5 1.5L7.5 5.5C7.5 5.77614 7.72386 6 8 6C8.27614 6 8.5 5.77614 8.5 5.5L8.5 1ZM1.35355 8.35355L8.35355 1.35355L7.64645 0.646447L0.646447 7.64645L1.35355 8.35355Z" fill="#67C8B7"/>
              </svg>
            </div>
          </a> -->
				</template>
			</vuetable>
		</div>
		<!-- end table -->

		<!-- pagination -->
		<div class="row">
			<div class="col-md-5">
				<div class="row no-gutters">
					<div class="col-lg-3">
						<div class="form-group float-lg-left">
							<select v-model="perPage" class="form-control form-control-sm" @change="onChangeTableLength">
								<option v-for="opt in lengthMenu" :key="opt" :value="opt">
									{{ opt }}
								</option>
							</select>
						</div>
					</div>
					<div class="col-md-8">
						<vuetable-pagination-info ref="paginationInfo" class="
                justify-content-center justify-content-lg-start
                text-center text-lg-left
              ">
						</vuetable-pagination-info>
					</div>
				</div>
			</div>
			<div class="col-sm-12 col-md-7">
				<vuetable-pagination ref="pagination" :css="css.pagination" class="
            pagination pagination-rounded
            justify-content-center justify-content-lg-end
          " @vuetable-pagination:change-page="onChangePage">
				</vuetable-pagination>
			</div>
		</div>
		<!-- end pagination -->

		<b-modal id="modalBuyingDownPayment" size="xl" title="Create New Transaction" style="max-width: 90% !important;"
			@ok="submitBuying" ok-title="Submit" no-close-on-backdrop no-close-on-esc>
			<div>
				<form-wizard :start-index="0" ref="wizard" color="#51C1AD" style="
          margin-top: 2rem;
          " @on-complete="onComplete">
					<!-- Tab Content 1 -->
					<tab-content title="Create Cash Advance" :before-change="validateBuyingDownPayment">
						<!--  -->
						<template>
							<b-form>
								<div class="row mt-3">
									<div class="col-12">
										<div class="form-row">
											<div class="col-md-2">
												<label for="">Number <span class="text-danger" aria-required="true"> * </span></label>
												<div class="input-group">
													<v-select v-show="!isManualNumberBuyingDownPayment" v-model="dataBuyingDownPayment.number"
														:reduce="(option) => option.id" :options="listAutonumber" label="name"
														style="width: 81%; float: left;">
													</v-select>
													<input v-show="isManualNumberBuyingDownPayment" v-model="dataBuyingDownPayment.numberText"
														type="text" class="form-control form-control-sm" placeholder="Number" required />
													<b-input-group-append style="width: 19%; float: right;">
														<b-button class="btn btn-create btn-sm" style="background: #51C1AD;"
															@click="triggerManualNumberBuying">
															<i v-show="!isManualNumberBuyingDownPayment" class="mdi mdi-pencil"></i>
															<i v-show="isManualNumberBuyingDownPayment" class="mdi mdi-close"></i>
														</b-button>
													</b-input-group-append>
												</div>
											</div>
											<div class="col-md-2">
												<div class="form-group">
													<label for="">Vendor <span class="text-danger" aria-required="true"> * </span></label>
													<v-select v-model="dataBuyingDownPayment.vendor" :options="listVendor"
														:reduce="(option) => option.id" label="name" @input="changeVendor">
													</v-select>
												</div>
											</div>
											<div class="col-md-2">
												<div class="form-group">
													<label for="">Term <span class="text-danger" aria-required="true"> * </span></label>
													<v-select v-model="dataBuyingDownPayment.term" taggable :reduce="(option) => option.id"
														:options="listTerm" label="name" item-text="name" item-value="id" @input="changeTerm">
													</v-select>
												</div>
											</div>
											<div class="col-md-2">
												<div class="form-group">
													<label for="">Currency <span class="text-danger" aria-required="true"> * </span></label>
													<v-select v-model="dataBuyingDownPayment.currency" taggable :options="listCurrency"
														:reduce="(option) => option.id" label="code" item-text="code" item-value="id"
														@input="changeCurrency">
													</v-select>
												</div>
											</div>
											<div class="col-md-2">
												<label for="">Date (yyyy/mm/dd) <span class="text-danger" aria-required="true"> * </span></label>
												<date-picker v-model="dataBuyingDownPayment.date" :format="momentFormat" type="date" lang="en"
													style="height: 31px;" :placeholder="'Select Date'" @input="getTgl">
												</date-picker>
											</div>
											<div class="col-md-2">
												<div class="form-group">
													<label for="">Invoice No <span class="text-danger" aria-required="true"> * </span></label>
													<input v-model="dataBuyingDownPayment.invoice" type="text" class="form-control form-control-sm"
														placeholder="Invoice No" required />
												</div>
											</div>
											<div class="col-md-2">
												<div class="form-group">
													<label for="">Cash Advance <span class="text-danger" aria-required="true"> * </span></label>
													<input v-model="dataBuyingDownPayment.downPayment" type="text"
														class="form-control form-control-sm" placeholder="Cash Advance" required
														@keypress="isNumber($event)" />
												</div>
											</div>
											<div class="col-md-1">
												<label for=""></label>
												<div class="form-check mt-2">
													<input id="taxable" v-model="dataBuyingDownPayment.taxable" type="checkbox" true-value="1"
														false-value="0" class="form-check-input" style="width: 15px; height: 15px;">
													<label for="taxable">Taxable</label>
												</div>
											</div>
											<div v-show="dataBuyingDownPayment.taxable === '1'" class="col-md-2" >
												<div class="form-group">
													<label for="">PPN <span class="text-danger" aria-required="true"> * </span></label>
													<v-select v-model="dataBuyingDownPayment.ppn" :reduce="(option) => option.id"
														:options="listPercentage" label="description"
														 item-text="description" item-value="id" @input="(id) => changePpn(id, listPercentage.find(option => option.id === id))"
														>
													</v-select>
												</div>
											</div>
											<div class="col-md-2"
												v-show="(dataBuyingDownPayment.ppn !== undefined && parseInt(dataBuyingDownPayment.ppn) > 0 && dataBuyingDownPayment.taxable === '1')">
												<label for=""></label>
												<div class="form-check mt-2">
													<input id="inclusiveTax" v-model="dataBuyingDownPayment.inclusiveTax" type="checkbox"
														true-value="1" false-value="0" class="form-check-input" style="width: 15px; height: 15px;">
													<label for="inclusiveTax">Inclusive Tax</label>
												</div>
											</div>
											<div class="col-md-2"
												v-show="(dataBuyingDownPayment.ppn !== undefined && parseInt(dataBuyingDownPayment.ppn) > 0)">
												<div class="form-group">
													<label for="">Document Code <span class="text-danger" aria-required="true"> * </span></label>
													<v-select v-model="dataBuyingDownPayment.docCode" :options="listDocCodeNew"
														:reduce="(option) => option.code" label="text" @input="changeDocCode" item-text="text"
														item-value="code">
													</v-select>
												</div>
											</div>
											<div class="col-md-3"
												v-show="(dataBuyingDownPayment.ppn !== undefined && parseInt(dataBuyingDownPayment.ppn) > 0 && showTaxType === true)">
												<div class="form-group">
													<label for="">Tax Type <span class="text-danger" aria-required="true"> * </span></label>
													<v-select v-model="dataBuyingDownPayment.taxType" :options="listTaxTypeBuying"
														:reduce="(option) => option.code" label="text" item-text="text" item-value="code">
													</v-select>
												</div>
											</div>

											<div class="col-md-2" v-show="(dataBuyingDownPayment.docCode !== undefined && dataBuyingDownPayment.ppn > 0 && dataBuyingDownPayment.docCode === 'CTAS_IMPORT' )">
                        <div class="form-group">
                          <label for="">Transaction Document</label>
                          <v-select v-model="dataBuyingDownPayment.trx_doc_type" :options="listTrxDocTypeBuying" :reduce="(option) => option.code" label="text" item-text="text" item-value="code">
                          </v-select>
                        </div>
                      </div>
											<div class="col-md-2" v-show="showRate">
												<div class="form-group">
													<label for="">Commercial Rate <span class="text-danger" aria-required="true"> * </span></label>
													<input v-model="dataBuyingDownPayment.commercialRate" type="text"
														class="form-control form-control-sm" placeholder="Commercial Rate" required
														@keypress="isNumber($event)" />
												</div>
											</div>
											<div class="col-md-2" v-show="showRate">
												<div class="form-group">
													<label for="">Fiscal Rate <span class="text-danger" aria-required="true"> * </span></label>
													<input v-model="dataBuyingDownPayment.fiscalRate" type="text"
														class="form-control form-control-sm" placeholder="Fiscal Rate" required
														@keypress="isNumber($event)" />
												</div>
											</div>
											<div class="col-md-2">
												<div class="form-group">
													<label for="">Description</label>
													<textarea v-model="dataBuyingDownPayment.description" class="form-control form-control-sm"
														placeholder="Description" cols="30" rows="3"></textarea>
												</div>
											</div>
											<div class="col-md-1" style="display: grid; align-items: center; justify-content: start;" v-show="this.companyIsUsingWorkingInstruction === 1">
												<div>
												<input id="isWiNumber" v-model="dataBuyingDownPayment.isWINumber" type="checkbox" true-value="1" false-value="0" class="form-check-input" style="width: 15px; height: 15px;position: relative;margin-left: 0; margin-right: 5px;">
												<label for="isWiNumber" style="margin-bottom: 4px;">
													WI Number
												</label>
												</div>
											</div>
											<div class="col-md-2" v-show="dataBuyingDownPayment.isWINumber.toString() === '1' && this.companyIsUsingWorkingInstruction === 1">
												<div class="form-group">
												<label for="includeTax" style="margin-bottom: 4px;">
													WI Number
												</label>
												<v-select v-model="dataBuyingDownPayment.wINumber" :options="listWINumber" :reduce="(option) => option.id" label="number" item-text="number" item-value="id">
                          						</v-select>
												<!-- <select v-model="dataBuyingDownPayment.wINumber" class="form-control form-control-sm" data-live-search="true" placeholder="Choose an WI Number" :disabled="false">
													<option v-for="(isi, key) in listWINumber" :key="key" :value="isi.id">
													{{isi.number}}
													</option>
												</select> -->
												</div>
											</div>
										</div>
									</div>
								</div>
							</b-form>
						</template>

						<div class="wizard-footer-left">
							<b-button class="btn-cancel" style="position: absolute; margin-top: 11px;"
								@click.prevent="closeModalDownPayment">
								Cancel
							</b-button>
						</div>
					</tab-content>
					<!-- End Tab Content 1 -->

					<!-- Tab Content 2 -->
					<tab-content title="Create Purchase Payment" :before-change="validatePurchasePayment">
						<template>
							<b-form>
								<div class="row mt-3">
									<div class="col-12">
										<div class="form-row">

											<div class="col-md-4">
												<div class="form-group">
													<label for="">Bank <span class="text-danger" aria-required="true"> * </span></label>
													<v-select v-model="dataBuyingPurchasePayment.bank" :options="listItemBank"
														:reduce="(option) => option.id" label="item_name" @input="changeBank">
													</v-select>
												</div>
											</div>
											<div class="col-md-2">
												<div class="form-group">
													<label for="">Payment Method <span class="text-danger" aria-required="true"> * </span></label>
													<v-select v-model="dataBuyingPurchasePayment.paymentMethod" :options="paymentMethods"
														:reduce="(option) => option.id" label="name">
													</v-select>
												</div>
											</div>
											<div class="col-md-2" v-show="dataBuyingPurchasePayment.paymentMethod === 'BANK_CHEQUE'">
												<div class="form-group">
													<label for="">Cheque No</label>
													<input v-model="dataBuyingPurchasePayment.chequeNo" class="form-control form-control-sm"
														placeholder="Cheque No" />
												</div>
											</div>
											<div class="col-md-2" v-show="dataBuyingPurchasePayment.paymentMethod === 'BANK_CHEQUE'">
												<label for="">Cheque Date <span class="text-danger" aria-required="true"> * </span></label>
												<date-picker v-model="dataBuyingPurchasePayment.chequeDate" :format="momentFormatChequeDate"
													type="date" lang="en" style="height: 31px;" :placeholder="'Select Date'"
													@input="getTglChequeDate">
												</date-picker>
											</div>
											<div class="col-md-2">
												<label for="">Number <span class="text-danger" aria-required="true"> * </span></label>
												<div class="input-group">
													<v-select v-show="!isManualNumberBuyingPurchasePayment"
														v-model="dataBuyingPurchasePayment.number" :reduce="(option) => option.id"
														:options="listAutonumberPP" label="name" style="width: 81%; float: left;">
													</v-select>
													<input v-show="isManualNumberBuyingPurchasePayment"
														v-model="dataBuyingPurchasePayment.numberText" type="text"
														class="form-control form-control-sm" placeholder="Number" required />
													<b-input-group-append style="width: 19%; float: right;">
														<b-button class="btn btn-create btn-sm" style="background: #51C1AD;"
															@click="triggerManualNumberBuyingPurchasePayment">
															<i v-show="!isManualNumberBuyingPurchasePayment" class="mdi mdi-pencil"></i>
															<i v-show="isManualNumberBuyingPurchasePayment" class="mdi mdi-close"></i>
														</b-button>
													</b-input-group-append>
												</div>
											</div>
											<div class="col-md-2">
												<label for="">Date (yyyy/mm/dd) <span class="text-danger" aria-required="true"> * </span></label>
												<date-picker v-model="dataBuyingPurchasePayment.date" :format="momentFormatPP" type="date"
													lang="en" style="height: 31px;" :placeholder="'Select Date'" @input="getTglPP">
												</date-picker>
											</div>
											<!-- <div class="col-md-2">
                          <div class="form-group">
                            <label for="">Discount Account</label>
                            <v-select v-model="dataBuyingPurchasePayment.discountAccount" taggable :reduce="(option) => option.id" :options="listItemBank" label="item_name" @input="changeDisc" item-text="item_name" item-value="id">
                            </v-select>
                          </div>
                        </div>
                        <div class="col-md-1">
                          <div class="form-group">
                            <label for="">.</label>
                            <v-select v-model="dataBuyingPurchasePayment.discountType" taggable :reduce="(option) => option.id" :options="discountTypes" label="name" item-text="name" item-value="id">
                            </v-select>
                          </div>
                        </div>
                        <div class="col-md-2">
                          <div class="form-group">
                            <label for="">Discount</label>
                            <input v-model="dataBuyingPurchasePayment.discount" type="text" class="form-control form-control-sm" placeholder="Discount" required @keypress="isNumber($event)" />
                          </div>
                        </div> -->
											<div class="col-md-2">
												<div class="form-group">
													<label for="">Currency <span class="text-danger" aria-required="true"> * </span></label>
													<v-select v-model="dataBuyingPurchasePayment.currencyBank" taggable :options="listCurrency"
														:reduce="(option) => option.id" label="code" item-text="code" item-value="id" disabled>
													</v-select>
												</div>
											</div>
											<!-- <div class="col-md-2" v-show="showRatePP">
                          <div class="form-group">
                            <label for="">Commercial Rate</label>
                            <input v-model="dataBuyingPurchasePayment.commercialRate" type="text" class="form-control form-control-sm" placeholder="Commercial Rate" required />
                          </div>
                        </div>
                        <div class="col-md-2" v-show="showRatePP">
                          <div class="form-group">
                            <label for="">Fiscal Rate</label>
                            <input v-model="dataBuyingPurchasePayment.fiscalRate" type="text" class="form-control form-control-sm" placeholder="Fiscal Rate" required />
                          </div>
                        </div> -->
											<div class="col-md-2">
												<div class="form-group">
													<label for="">Remarks</label>
													<textarea v-model="dataBuyingPurchasePayment.remarks" class="form-control form-control-sm"
														placeholder="Remarks" cols="30" rows="3"></textarea>
												</div>
											</div>

										</div>
									</div>
								</div>
								<div v-for="(isiDetail, d) in itemDiscountPP" :key="d" class="row mt-2">
									<div class="col-md-2">
										<div class="form-group">
											<label for="">Discount Account</label>
											<v-select v-model="isiDetail.discountAccount" taggable :reduce="(option) => option.id"
												:options="listItemDiscount" label="item_name" @input="changeDiscPP(d)" item-text="item_name"
												item-value="id">
											</v-select>
										</div>
									</div>
									<div class="col-md-2">
										<div class="form-group">
											<label for="">.</label>
											<v-select v-model="isiDetail.discountType" taggable :reduce="(option) => option.id"
												:options="discountTypes" label="name" item-text="name" item-value="id">
											</v-select>
										</div>
									</div>
									<div class="col-md-2">
										<div class="form-group">
											<label for="">Discount</label>
											<input v-model="isiDetail.discount" type="number" class="form-control form-control-sm"
												placeholder="Discount" required />
										</div>
									</div>
									<div class="col-md-2">
										<div class="form-group">
											<label for="">Project PP <span class="text-danger" aria-required="true"> * </span></label>
											<v-select v-model="isiDetail.project" :options="listJobfile" :reduce="(option) => option.id"
												label="jobfile_no" item-text="jobfile_no" item-value="id" required>
											</v-select>
										</div>
									</div>
									<div class="col-md-2">
										<div class="form-group">
											<label for="">Departemen <span class="text-danger" aria-required="true"> * </span></label>
											<v-select v-model="isiDetail.departemen" :options="listTruck" :reduce="(option) => option.id"
												label="truck_no" item-text="truck_no" item-value="id">
											</v-select>
										</div>
									</div>
									<div class="col-md-2">
										<a v-show="d || (!d && itemDiscountPP.length > 1)" href="javascript:void(0)"
											class="btn btn-danger btn-sm" @click="removeDiscountPP(d)" style="margin-top: 27px;"> <i
												class="fas fa-trash"></i>
										</a> &nbsp;
										<a v-show="d === itemDiscountPP.length - 1" href="javascript:void(0)"
											class="btn btn-create btn-sm mr-1" @click="addDiscountPP('ok')" style="margin-top: 27px;"><i
												class="fas fa-plus"></i></a>
									</div>
								</div>
							</b-form>
						</template>
					</tab-content>
					<!-- End Tab Content 2 -->

					<!-- Tab Content 3 -->
					<tab-content title="Create Purchase Payment Tax"
						v-if="isEnableTabTax && parseFloat(this.dataBuyingDownPayment.ppn) > 0"
						:before-change="validatePurchasePaymentTax">
						<template>
							<b-form>
								<div class="row mt-3">
									<div class="col-12">
										<div class="form-row">
											<div class="col-md-4">
												<div class="form-group">
													<label for="">Bank <span class="text-danger" aria-required="true"> * </span></label>
													<v-select v-model="dataBuyingPurchasePaymentTax.bank" :options="listItemBankIDR"
														:reduce="(option) => option.id" label="item_name" @input="changeBankTax">
													</v-select>
												</div>
											</div>
											<div class="col-md-2">
												<div class="form-group">
													<label for="">Payment Method <span class="text-danger" aria-required="true"> * </span></label>
													<v-select v-model="dataBuyingPurchasePaymentTax.paymentMethod" :options="paymentMethods"
														:reduce="(option) => option.id" label="name">
													</v-select>
												</div>
											</div>
											<div class="col-md-2" v-show="dataBuyingPurchasePaymentTax.paymentMethod === 'BANK_CHEQUE'">
												<div class="form-group">
													<label for="">Cheque No</label>
													<input v-model="dataBuyingPurchasePaymentTax.chequeNo" class="form-control form-control-sm"
														placeholder="Cheque No" />
												</div>
											</div>
											<div class="col-md-2" v-show="dataBuyingPurchasePaymentTax.paymentMethod === 'BANK_CHEQUE'">
												<label for="">Cheque Date <span class="text-danger" aria-required="true"> * </span></label>
												<date-picker v-model="dataBuyingPurchasePaymentTax.chequeDate" :format="momentFormatChequeDate"
													type="date" lang="en" style="height: 31px;" :placeholder="'Select Date'"
													@input="getTglChequeDate">
												</date-picker>
											</div>
											<div class="col-md-2">
												<label for="">Number <span class="text-danger" aria-required="true"> * </span></label>
												<div class="input-group">
													<v-select v-show="!isManualNumberBuyingPurchasePaymentTax"
														v-model="dataBuyingPurchasePaymentTax.number" :reduce="(option) => option.id"
														:options="listAutonumberPPTax" label="name" style="width: 81%; float: left;">
													</v-select>
													<input v-show="isManualNumberBuyingPurchasePaymentTax"
														v-model="dataBuyingPurchasePaymentTax.numberText" type="text"
														class="form-control form-control-sm" placeholder="Number" required />
													<b-input-group-append style="width: 19%; float: right;">
														<b-button class="btn btn-create btn-sm" style="background: #51C1AD;"
															@click="triggerManualNumberBuyingPurchasePaymentTax">
															<i v-show="!isManualNumberBuyingPurchasePaymentTax" class="mdi mdi-pencil"></i>
															<i v-show="isManualNumberBuyingPurchasePaymentTax" class="mdi mdi-close"></i>
														</b-button>
													</b-input-group-append>
												</div>
											</div>
											<div class="col-md-2">
												<label for="">Date (yyyy/mm/dd) <span class="text-danger" aria-required="true"> * </span></label>
												<date-picker v-model="dataBuyingPurchasePaymentTax.date" :format="momentFormatPPTax" type="date"
													lang="en" style="height: 31px;" :placeholder="'Select Date'" @input="getTglPPTax">
												</date-picker>
											</div>
											<!-- <div class="col-md-2">
                          <div class="form-group">
                            <label for="">Discount Account</label>
                            <v-select v-model="dataBuyingPurchasePaymentTax.discountAccount" taggable :reduce="(option) => option.id" :options="listItemBank" label="item_name" @input="changeDiscTax" item-text="item_name" item-value="id">
                            </v-select>
                          </div>
                        </div>
                        <div class="col-md-1">
                          <div class="form-group">
                            <label for="">.</label>
                            <v-select v-model="dataBuyingPurchasePaymentTax.discountType" taggable :reduce="(option) => option.id" :options="discountTypes" label="name" item-text="name" item-value="id">
                            </v-select>
                          </div>
                        </div>
                        <div class="col-md-2">
                          <div class="form-group">
                            <label for="">Discount</label>
                            <input v-model="dataBuyingPurchasePaymentTax.discount" type="text" class="form-control form-control-sm" placeholder="Discount" required @keypress="isNumber($event)" />
                          </div>
                        </div> -->
											<div class="col-md-2">
												<div class="form-group">
													<label for="">Currency</label>
													<v-select v-model="dataBuyingPurchasePaymentTax.currencyBank" taggable :options="listCurrency"
														:reduce="(option) => option.id" label="code" item-text="code" item-value="id" disabled>
													</v-select>
												</div>
											</div>
											<!-- <div class="col-md-2" v-show="showRatePP">
                          <div class="form-group">
                            <label for="">Commercial Rate</label>
                            <input v-model="dataBuyingPurchasePaymentTax.commercialRate" type="text" class="form-control form-control-sm" placeholder="Commercial Rate" required />
                          </div>
                        </div>
                        <div class="col-md-2" v-show="showRatePP">
                          <div class="form-group">
                            <label for="">Fiscal Rate</label>
                            <input v-model="dataBuyingPurchasePaymentTax.fiscalRate" type="text" class="form-control form-control-sm" placeholder="Fiscal Rate" required />
                          </div>
                        </div> -->
											<div class="col-md-2">
												<div class="form-group">
													<label for="">Remarks</label>
													<textarea v-model="dataBuyingPurchasePaymentTax.remarks" class="form-control form-control-sm"
														placeholder="Remarks" cols="30" rows="3"></textarea>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div v-for="(isiDetail, d) in itemDiscountPPTax" :key="d" class="row mt-2">
									<div class="col-md-3">
										<div class="form-group">
											<label for="">Discount Account</label>
											<v-select v-model="isiDetail.discountAccount" taggable :reduce="(option) => option.id"
												:options="listItemDiscount" label="item_name" @input="changeDiscPPTax(d)" item-text="item_name"
												item-value="id">
											</v-select>
										</div>
									</div>
									<div class="col-md-2">
										<div class="form-group">
											<label for="">.</label>
											<v-select v-model="isiDetail.discountType" taggable :reduce="(option) => option.id"
												:options="discountTypes" label="name" item-text="name" item-value="id">
											</v-select>
										</div>
									</div>
									<div class="col-md-2">
										<div class="form-group">
											<label for="">Discount</label>
											<input v-model="isiDetail.discount" type="number" class="form-control form-control-sm"
												placeholder="Discount" required />
										</div>
									</div>
									<div class="col-md-2">
										<a v-show="d || (!d && itemDiscountPPTax.length > 1)" href="javascript:void(0)"
											class="btn btn-danger btn-sm" @click="removeDiscountPPTax(d)" style="margin-top: 27px;"> <i
												class="fas fa-trash"></i>
										</a> &nbsp;
										<a v-show="d === itemDiscountPPTax.length - 1" href="javascript:void(0)"
											class="btn btn-create btn-sm mr-1" @click="addDiscountPPTax('ok')" style="margin-top: 27px;"><i
												class="fas fa-plus"></i></a>
									</div>
								</div>
							</b-form>
						</template>
					</tab-content>
					<!-- End Tab Content 2 -->

					<button slot="prev" class="btn-cancel">Previous</button>
					<button slot="next" class="btn-next">Next</button>


					<button slot="finish" class="btn-finish" :disabled="isLoadingForm">Finish</button>
				</form-wizard>
			</div>
		</b-modal>


		<b-modal id="modalBuyingDownPaymentEdit" size="xl" title="Edit Transaction" style="max-width: 90% !important;"
			@ok="submitInfoBuying" ok-title="Save" :ok-disabled="isLoadingForm" no-close-on-backdrop>
			<div>
				<span style="font-weight: bold;font-size: 16px;">Cash Advance</span>
				<div class="row mt-3 mb-3">
					<div class="col-12">
						<div class="form-row">
							<div class="col-md-2">
								<label for="">Date (yyyy/mm/dd) <span class="text-danger" aria-required="true"> * </span></label>
								<date-picker v-model="dataSingleBuyingDownPayment.date" :format="momentFormatEdit" type="date" lang="en"
									style="height: 31px;" :placeholder="'Select Date'" @input="getTglEdit">
								</date-picker>
							</div>
							<div class="col-md-2">
								<label for="">Number <span class="text-danger" aria-required="true"> * </span></label>
								<!-- <div class="input-group">
                  <input v-model="dataSingleBuyingDownPayment.number" type="text" class="form-control form-control-sm" placeholder="Number" />
                </div> -->
								<div class="input-group">
									<v-select v-show="!isManualNumberBuyingDownPaymentEdit" v-model="dataSingleBuyingDownPayment.number"
										:reduce="(option) => option.id" :options="listAutonumber" label="name"
										style="width: 81%; float: left;">
										<!-- <v-select v-show="!isManualNumberBuyingDownPaymentEdit" v-model="dataSingleBuyingDownPayment.number" :reduce="(option) => option.id" :options="listAutonumber" label="name" style="width: 81%; float: left;"> -->
									</v-select>
									<input v-show="isManualNumberBuyingDownPaymentEdit" v-model="dataSingleBuyingDownPayment.numberText"
										type="text" class="form-control form-control-sm" placeholder="Number" required />
									<b-input-group-append style="width: 19%; float: right;">
										<b-button class="btn btn-create btn-sm" style="background: #51C1AD;"
											@click="triggerManualNumberBuyingDownPaymentEdit">
											<i v-show="!isManualNumberBuyingDownPaymentEdit" class="mdi mdi-pencil"></i>
											<i v-show="isManualNumberBuyingDownPaymentEdit" class="mdi mdi-close"></i>
										</b-button>
									</b-input-group-append>
								</div>
							</div>
							<div class="col-md-2">
								<div class="form-group">
									<label for="">Invoice No <span class="text-danger" aria-required="true"> * </span></label>
									<input v-model="dataSingleBuyingDownPayment.invoice_no" type="text" class="form-control form-control-sm"
										placeholder="Invoice No" required />
								</div>
							</div>
							<div class="col-md-2">
								<div class="form-group">
									<label for="">Term <span class="text-danger" aria-required="true"> * </span></label>
									<v-select v-model="dataSingleBuyingDownPayment.term" taggable :reduce="(option) => option.id"
										:options="listTerm" label="name" item-text="name" item-value="id" @input="changeTerm">
									</v-select>
								</div>
							</div>
							<div class="col-md-2">
								<div class="form-group">
									<label for="">Cash Advance <span class="text-danger" aria-required="true"> * </span></label>
									<input v-model="dataSingleBuyingDownPayment.down_payment" type="text"
										class="form-control form-control-sm" placeholder="Cash Advance" required
										@keypress="isNumber($event)" />
								</div>
							</div>
							<div class="col-md-2">
								<label for=""></label>
								<div class="form-check mt-2">
									<input id="taxable" v-model="dataSingleBuyingDownPayment.taxable" type="checkbox" true-value="1"
										false-value="0" class="form-check-input" style="width: 15px; height: 15px;">
									<label for="taxable">Taxable</label>
								</div>
							</div>
							<div class="col-md-2" v-show="parseInt(dataSingleBuyingDownPayment.taxable) === 1">
								<div class="form-group">
									<label for="">PPN <span class="text-danger" aria-required="true"> * </span></label>
									<v-select v-model="dataSingleBuyingDownPayment.ppn" :reduce="(option) => option.id"
										:options="listPercentage" item-text="description" item-value="id" label="description" @input="(id) => changePpn(id, listPercentage.find(option => option.id === id))">
									</v-select>
								</div>
							</div>
							<div class="col-md-2"
								v-show="(dataSingleBuyingDownPayment.ppn !== undefined && parseInt(dataSingleBuyingDownPayment.ppn) > 0 && parseInt(dataSingleBuyingDownPayment.taxable) === 1)">
								<label for=""></label>
								<div class="form-check mt-2">
									<input id="inclusiveTax" v-model="dataSingleBuyingDownPayment.inclusive_tax" type="checkbox"
										true-value="1" false-value="0" class="form-check-input" style="width: 15px; height: 15px;">
									<label for="inclusiveTax">Inclusive Tax</label>
								</div>
							</div>
							<div class="col-md-2"
								v-show="(dataSingleBuyingDownPayment.ppn !== undefined && parseInt(dataSingleBuyingDownPayment.ppn) > 0 && parseInt(dataSingleBuyingDownPayment.taxable) === 1)">
								<div class="form-group">
									<label for="">Document Code <span class="text-danger" aria-required="true"> * </span></label>
									<v-select v-model="dataSingleBuyingDownPayment.doc_code" :options="listDocCodeNew"
										:reduce="(option) => option.code" label="text" @input="changeDocCodeEdit" item-text="text"
										item-value="code">
									</v-select>
								</div>
							</div>
							<div class="col-md-3"
								v-show="(dataSingleBuyingDownPayment.ppn !== undefined && parseInt(dataSingleBuyingDownPayment.ppn) > 0 && parseInt(dataSingleBuyingDownPayment.taxable) === 1 && showTaxType === true)">
								<div class="form-group">
									<label for="">Tax Type <span class="text-danger" aria-required="true"> * </span></label>
									<v-select v-model="dataSingleBuyingDownPayment.tax_type" :taggable="false" :options="listTaxTypeBuying"
										:reduce="(option) => option.code" label="text" item-text="text" item-value="code">
									</v-select>
								</div>
							</div>

							<div class="col-md-2" v-show="(dataSingleBuyingDownPayment.doc_code !== undefined && dataSingleBuyingDownPayment.ppn > 0 && dataSingleBuyingDownPayment.doc_code === 'CTAS_IMPORT' )">
								<div class="form-group">
									<label for="">Transaction Document</label>
									<v-select v-model="dataSingleBuyingDownPayment.trx_doc_type" :options="listTrxDocTypeBuying" :reduce="(option) => option.code" label="text" item-text="text" item-value="code">
									</v-select>
								</div>
							</div>
							<div class="col-md-2">
								<div class="form-group">
									<label for="">Description</label>
									<textarea v-model="dataSingleBuyingDownPayment.description" class="form-control form-control-sm"
										placeholder="Description" cols="30" rows="3"></textarea>
								</div>
							</div>
							<div class="col-md-1" style="display: grid; align-items: center; justify-content: start;" v-show="this.companyIsUsingWorkingInstruction === 1">
								<div>
								<input id="isWiNumber" v-model="dataSingleBuyingDownPayment.isWINumber" type="checkbox" true-value="1" false-value="0" class="form-check-input" style="width: 15px; height: 15px;position: relative;margin-left: 0; margin-right: 5px;">
								<label for="isWiNumber" style="margin-bottom: 4px;">
									WI Number
								</label>
								</div>
							</div>
							<div class="col-md-2" v-show="dataSingleBuyingDownPayment.isWINumber === '1' && this.companyIsUsingWorkingInstruction === 1">
								<div class="form-group">
								<label for="includeTax" style="margin-bottom: 4px;">
									WI Number
								</label>
								<v-select v-model="dataSingleBuyingDownPayment.id_wi_number" :options="listWINumber" :reduce="(option) => option.id" label="number" item-text="number" item-value="id">
								</v-select>
								<!-- <select v-model="dataSingleBuyingDownPayment.id_wi_number" class="form-control form-control-sm" data-live-search="true" placeholder="Choose an WI Number" :disabled="false">
									<option v-for="(isi, key) in listWINumber" :key="key" :value="isi.id">
									{{isi.number}}
									</option>
								</select> -->
								</div>
							</div>
							<div class="col-md-2">
								<div class="form-group">
									<label for="">Currency <span class="text-danger" aria-required="true"> * </span></label>
									<input v-model="dataSingleBuyingDownPayment.symbol" type="text" class="form-control form-control-sm"
										placeholder="Currency" required disabled />
								</div>
							</div>
							<div class="col-md-2">
								<div class="form-group">
									<label for="">Commercial Rate <span class="text-danger" aria-required="true"> * </span></label>
									<input v-model="dataSingleBuyingDownPayment.price_kurs" type="text" class="form-control form-control-sm"
										placeholder="Commercial Rate" disabled />
								</div>
							</div>
							<div class="col-md-2">
								<div class="form-group">
									<label for="">Fiscal Rate <span class="text-danger" aria-required="true"> * </span></label>
									<input v-model="dataSingleBuyingDownPayment.fiscal_kurs" type="text"
										class="form-control form-control-sm" placeholder="Fiscal Rate" disabled />
								</div>
							</div>
						</div>
					</div>
				</div>
				<span style="font-weight: bold;font-size: 16px;">Purchase Payment</span>
				<div class="row mt-3">
					<div class="col-12">
						<div class="form-row">
							<div class="col-md-3">
								<div class="form-group">
									<label for="">Bank <span class="text-danger" aria-required="true"> * </span></label>
									<v-select v-model="dataSingleBuyingPurchasePayment.bank" :options="listItemBank"
										:reduce="(option) => option.id" label="item_name" @change="changeBankEdit">
									</v-select>
								</div>
							</div>
							<div class="col-md-2">
								<div class="form-group">
									<label for="">Payment Method <span class="text-danger" aria-required="true"> * </span></label>
									<v-select v-model="dataSingleBuyingPurchasePayment.payment_method" :options="paymentMethods"
										:reduce="(option) => option.id" label="name">
									</v-select>
								</div>
							</div>
							<div class="col-md-2" v-show="dataSingleBuyingPurchasePayment.payment_method === 'BANK_CHEQUE'">
								<div class="form-group">
									<label for="">Cheque No</label>
									<input v-model="dataSingleBuyingPurchasePayment.cheque_no" class="form-control form-control-sm"
										placeholder="Cheque No" />
								</div>
							</div>
							<div class="col-md-2" v-show="dataSingleBuyingPurchasePayment.payment_method === 'BANK_CHEQUE'">
								<label for="">Cheque Date <span class="text-danger" aria-required="true"> * </span></label>
								<date-picker v-model="dataSingleBuyingPurchasePayment.cheque_date" :format="momentFormatEditChequeDate"
									type="date" lang="en" style="height: 31px;" :placeholder="'Select Date'" @input="getTglEditChequeDate">
								</date-picker>
							</div>
							<div class="col-md-2">
								<label for="">Number <span class="text-danger" aria-required="true"> * </span></label>
								<!-- <div class="input-group">
                  <input v-model="dataSingleBuyingPurchasePayment.number" type="text" class="form-control form-control-sm" placeholder="Number" />
                </div> -->
								<div class="input-group">
									<v-select v-show="!isManualNumberBuyingPurchasePaymentEdit"
										v-model="dataSingleBuyingPurchasePayment.number" :reduce="(option) => option.id"
										:options="listAutonumberPP" label="name" style="width: 81%; float: left;">
										<!-- <v-select v-show="!isManualNumberBuyingPurchasePaymentEdit" v-model="dataSingleBuyingPurchasePayment.number" :reduce="(option) => option.id" :options="listAutonumber" label="name" style="width: 81%; float: left;"> -->
									</v-select>
									<input v-show="isManualNumberBuyingPurchasePaymentEdit"
										v-model="dataSingleBuyingPurchasePayment.numberText" type="text" class="form-control form-control-sm"
										placeholder="Number" required />
									<b-input-group-append style="width: 19%; float: right;">
										<b-button class="btn btn-create btn-sm" style="background: #51C1AD;"
											@click="triggerManualNumberBuyingPurchasePaymentEdit">
											<i v-show="!isManualNumberBuyingPurchasePaymentEdit" class="mdi mdi-pencil"></i>
											<i v-show="isManualNumberBuyingPurchasePaymentEdit" class="mdi mdi-close"></i>
										</b-button>
									</b-input-group-append>
								</div>
							</div>
							<div class="col-md-2">
								<label for="">Date (yyyy/mm/dd) <span class="text-danger" aria-required="true"> * </span></label>
								<date-picker v-model="dataSingleBuyingPurchasePayment.date" :format="momentFormatPPEdit" type="date"
									lang="en" style="height: 31px;" :placeholder="'Select Date'" @input="getTglPPEdit">
								</date-picker>
							</div>
							<!-- <div class="col-md-3">
                <div class="form-group">
                  <label for="">Discount Account</label>
                  <v-select v-model="dataSingleBuyingPurchasePayment.discount_account" taggable :reduce="(option) => option.id" :options="listItemBank" label="item_name" @input="changeDiscEdit" item-text="item_name" item-value="id">
                  </v-select>
                </div>
              </div>
              <div class="col-md-1">
                <div class="form-group">
                  <label for="">.</label>
                  <v-select v-model="dataSingleBuyingPurchasePayment.discount_type" taggable :reduce="(option) => option.id" :options="discountTypes" label="name" item-text="name" item-value="id">
                  </v-select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="">Discount</label>
                  <input v-model="dataSingleBuyingPurchasePayment.discount" type="text" class="form-control form-control-sm" placeholder="Discount" required @keypress="isNumber($event)" />
                </div>
              </div> -->
							<div class="col-md-2">
								<div class="form-group">
									<label for="">Bank Currency <span class="text-danger" aria-required="true"> * </span></label>
									<v-select v-model="dataSingleBuyingPurchasePayment.id_currency_bank" taggable :options="listCurrency"
										:reduce="(option) => option.id" label="code" item-text="code" item-value="id" disabled>
									</v-select>
								</div>
							</div>
							<div class="col-md-2">
								<div class="form-group">
									<label for="">Commercial Rate <span class="text-danger" aria-required="true"> * </span></label>
									<input v-model="dataSingleBuyingPurchasePayment.price_kurs" type="text"
										class="form-control form-control-sm" placeholder="Commercial Rate" required disabled />
								</div>
							</div>
							<div class="col-md-2">
								<div class="form-group">
									<label for="">Fiscal Rate <span class="text-danger" aria-required="true"> * </span></label>
									<input v-model="dataSingleBuyingPurchasePayment.fiscal_kurs" type="text"
										class="form-control form-control-sm" placeholder="Fiscal Rate" required disabled />
								</div>
							</div>
							<div class="col-md-2">
								<div class="form-group">
									<label for="">Remarks</label>
									<textarea v-model="dataSingleBuyingPurchasePayment.remarks" class="form-control form-control-sm"
										placeholder="Remarks" cols="30" rows="3"></textarea>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-for="(isiDetail, d) in itemDiscountPP" :key="d" class="row mt-1">
					<div class="col-md-2">
						<div class="form-group">
							<label for="">Discount Account</label>
							<v-select v-model="isiDetail.discountAccount" taggable :reduce="(option) => option.id"
								:options="listItemDiscount" label="item_name" @input="changeDiscPP(d)" item-text="item_name"
								item-value="id">
							</v-select>
						</div>
					</div>
					<div class="col-md-2">
						<div class="form-group">
							<label for="">.</label>
							<v-select v-model="isiDetail.discountType" taggable :reduce="(option) => option.id" :options="discountTypes"
								label="name" item-text="name" item-value="id">
							</v-select>
						</div>
					</div>
					<div class="col-md-2">
						<div class="form-group">
							<label for="">Discount</label>
							<input v-model="isiDetail.discount" type="number" class="form-control form-control-sm"
								placeholder="Discount" required />
						</div>
					</div>
					<div class="col-md-2">
						<div class="form-group">
							<label for="">Project PP <span class="text-danger" aria-required="true"> * </span></label>
							<v-select v-model="isiDetail.project" :options="listJobfile" :reduce="(option) => option.id"
								label="jobfile_no" item-text="jobfile_no" item-value="id" required>
							</v-select>
						</div>
					</div>
					<div class="col-md-2">
						<div class="form-group">
							<label for="">Departemen <span class="text-danger" aria-required="true"> * </span></label>
							<v-select v-model="isiDetail.departemen" :options="listTruck" :reduce="(option) => option.id"
								label="truck_no" item-text="truck_no" item-value="id">
							</v-select>
						</div>
					</div>
					<div class="col-md-2">
						<a v-show="d || (!d && itemDiscountPP.length > 1)" href="javascript:void(0)" class="btn btn-danger btn-sm"
							@click="removeDiscountPP(d)" style="margin-top: 27px;"> <i class="fas fa-trash"></i>
						</a> &nbsp;
						<a v-show="d === itemDiscountPP.length - 1" href="javascript:void(0)" class="btn btn-create btn-sm mr-1"
							@click="addDiscountPP('ok')" style="margin-top: 27px;"><i class="fas fa-plus"></i></a>
					</div>
				</div>
				<hr style="border-color: #EAEAEA;">
				<span style="font-weight: bold;font-size: 16px;"
					v-show="editTaxAble && parseFloat(this.dataSingleBuyingDownPayment.ppn) > 0 && parseInt(dataSingleBuyingDownPayment.taxable) === 1">Purchase
					Payment Tax</span>
				<div class="row mt-3"
					v-show="editTaxAble && parseFloat(this.dataSingleBuyingDownPayment.ppn) > 0 && parseInt(dataSingleBuyingDownPayment.taxable) === 1">
					<div class="col-12">
						<div class="form-row">
							<div class="col-md-3">
								<div class="form-group">
									<label for="">Bank <span class="text-danger" aria-required="true"> * </span></label>
									<v-select v-model="dataSingleBuyingPurchasePaymentTax.bank" :options="listItemBank"
										:reduce="(option) => option.id" label="item_name">
									</v-select>
								</div>
							</div>
							<div class="col-md-2">
								<div class="form-group">
									<label for="">Payment Method <span class="text-danger" aria-required="true"> * </span></label>
									<v-select v-model="dataSingleBuyingPurchasePaymentTax.payment_method" :options="paymentMethods"
										:reduce="(option) => option.id" label="name">
									</v-select>
								</div>
							</div>
							<div class="col-md-2" v-show="dataSingleBuyingPurchasePaymentTax.payment_method === 'BANK_CHEQUE'">
								<div class="form-group">
									<label for="">Cheque No</label>
									<input v-model="dataSingleBuyingPurchasePaymentTax.cheque_no" class="form-control form-control-sm"
										placeholder="Cheque No" />
								</div>
							</div>
							<div class="col-md-2" v-show="dataSingleBuyingPurchasePaymentTax.payment_method === 'BANK_CHEQUE'">
								<label for="">Cheque Date <span class="text-danger" aria-required="true"> * </span></label>
								<date-picker v-model="dataSingleBuyingPurchasePaymentTax.cheque_date"
									:format="momentFormatEditTaxChequeDate" type="date" lang="en" style="height: 31px;"
									:placeholder="'Select Date'" @input="getTglEditTaxChequeDate">
								</date-picker>
							</div>
							<div class="col-md-2">
								<label for="">Number</label>
								<!-- <div class="input-group">
                  <input v-model="dataSingleBuyingPurchasePaymentTax.number" type="text" class="form-control form-control-sm" placeholder="Number" />
                </div> -->
								<div class="input-group">
									<v-select v-show="!isManualNumberBuyingPurchasePaymentTaxEdit"
										v-model="dataSingleBuyingPurchasePaymentTax.number" :reduce="(option) => option.id"
										:options="listAutonumberPPTax" label="name" style="width: 81%; float: left;">
										<!-- <v-select v-show="!isManualNumberPurchasePaymentTaxEdit" v-model="dataSingleBuyingPurchasePaymentTax.number" :reduce="(option) => option.id" :options="listAutonumber" label="name" style="width: 81%; float: left;"> -->
									</v-select>
									<input v-show="isManualNumberBuyingPurchasePaymentTaxEdit"
										v-model="dataSingleBuyingPurchasePaymentTax.numberText" type="text"
										class="form-control form-control-sm" placeholder="Number" required />
									<b-input-group-append style="width: 19%; float: right;">
										<b-button class="btn btn-create btn-sm" style="background: #51C1AD;"
											@click="triggerManualNumberBuyingPurchasePaymentTaxEdit">
											<i v-show="!isManualNumberBuyingPurchasePaymentTaxEdit" class="mdi mdi-pencil"></i>
											<i v-show="isManualNumberBuyingPurchasePaymentTaxEdit" class="mdi mdi-close"></i>
										</b-button>
									</b-input-group-append>
								</div>
							</div>
							<div class="col-md-2">
								<label for="">Date (yyyy/mm/dd) <span class="text-danger" aria-required="true"> * </span></label>
								<date-picker v-model="dataSingleBuyingPurchasePaymentTax.date" :format="momentFormatPPTaxEdit" type="date"
									lang="en" style="height: 31px;" :placeholder="'Select Date'" @input="getTglPPTaxEdit">
								</date-picker>
							</div>
							<!-- <div class="col-md-3">
                <div class="form-group">
                  <label for="">Discount Account</label>
                  <v-select v-model="dataSingleBuyingPurchasePaymentTax.discount_account" taggable :reduce="(option) => option.id" :options="listItemBank" label="item_name" @input="changeDiscTaxEdit" item-text="item_name" item-value="id">
                  </v-select>
                </div>
              </div>
              <div class="col-md-1">
                <div class="form-group">
                  <label for="">.</label>
                  <v-select v-model="dataSingleBuyingPurchasePaymentTax.discount_type" taggable :reduce="(option) => option.id" :options="discountTypes" label="name" item-text="name" item-value="id">
                  </v-select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="">Discount</label>
                  <input v-model="dataSingleBuyingPurchasePaymentTax.discount" type="text" class="form-control form-control-sm" placeholder="Discount" required @keypress="isNumber($event)" />
                </div>
              </div> -->
							<div class="col-md-2">
								<div class="form-group">
									<label for="">Bank Currency <span class="text-danger" aria-required="true"> * </span></label>
									<v-select v-model="dataSingleBuyingPurchasePaymentTax.id_currency_bank" taggable :options="listCurrency"
										:reduce="(option) => option.id" label="code" item-text="code" item-value="id" disabled>
									</v-select>
								</div>
							</div>
							<div class="col-md-2">
								<div class="form-group">
									<label for="">Remarks</label>
									<textarea v-model="dataSingleBuyingPurchasePaymentTax.remarks" class="form-control form-control-sm"
										placeholder="Remarks" cols="30" rows="3"></textarea>
								</div>
							</div>
						</div>
					</div>
				</div>

				<span style="font-weight: bold;font-size: 16px;"
					v-show="!editTaxAble && parseFloat(this.dataSingleBuyingDownPayment.ppn) > 0 && parseInt(dataSingleBuyingDownPayment.taxable) === 1 && parseInt(this.dataSingleBuyingDownPayment.id_currency) !== 0">Purchase
					Payment Tax</span>
				<div class="row mt-3"
					v-show="!editTaxAble && parseFloat(this.dataSingleBuyingDownPayment.ppn) > 0 && parseInt(dataSingleBuyingDownPayment.taxable) === 1 && parseInt(this.dataSingleBuyingDownPayment.id_currency) !== 0">
					<div class="col-12">
						<div class="form-row">
							<div class="col-md-4">
								<div class="form-group">
									<label for="">Bank <span class="text-danger" aria-required="true"> * </span></label>
									<v-select v-model="dataSingleBuyingPurchasePaymentTax.bank" :options="listItemBank"
										:reduce="(option) => option.id" label="item_name" @input="changeBankTax">
									</v-select>
								</div>
							</div>
							<div class="col-md-2">
								<div class="form-group">
									<label for="">Payment Method <span class="text-danger" aria-required="true"> * </span></label>
									<v-select v-model="dataSingleBuyingPurchasePaymentTax.payment_method" :options="paymentMethods"
										:reduce="(option) => option.id" label="name">
									</v-select>
								</div>
							</div>
							<div class="col-md-2">
								<label for="">Number <span class="text-danger" aria-required="true"> * </span></label>
								<div class="input-group">
									<v-select v-show="!isManualNumberBuyingPurchasePaymentTax"
										v-model="dataSingleBuyingPurchasePaymentTax.id_autonumber" :reduce="(option) => option.id"
										:options="listAutonumberPPTax" label="name" style="width: 81%; float: left;">
									</v-select>
									<input v-show="isManualNumberBuyingPurchasePaymentTax"
										v-model="dataSingleBuyingPurchasePaymentTax.number" type="text" class="form-control form-control-sm"
										placeholder="Number" required />
									<b-input-group-append style="width: 19%; float: right;">
										<b-button class="btn btn-create btn-sm" style="background: #51C1AD;"
											@click="triggerManualNumberBuyingPurchasePaymentTax">
											<i v-show="!isManualNumberBuyingPurchasePaymentTax" class="mdi mdi-pencil"></i>
											<i v-show="isManualNumberBuyingPurchasePaymentTax" class="mdi mdi-close"></i>
										</b-button>
									</b-input-group-append>
								</div>
							</div>
							<div class="col-md-2">
								<label for="">Date (yyyy/mm/dd) <span class="text-danger" aria-required="true"> * </span></label>
								<date-picker v-model="dataSingleBuyingPurchasePaymentTax.date" :format="momentFormatPPTaxEdit" type="date"
									lang="en" style="height: 31px;" :placeholder="'Select Date'" @input="getTglPPTaxEdit">
								</date-picker>
							</div>
							<!-- <div class="col-md-2">
                <div class="form-group">
                  <label for="">Discount Account</label>
                  <v-select v-model="dataSingleBuyingPurchasePaymentTax.discount_account" taggable :reduce="(option) => option.id" :options="listItemBank" label="item_name" @input="changeDiscTaxEdit" item-text="item_name" item-value="id">
                  </v-select>
                </div>
              </div>
              <div class="col-md-1">
                <div class="form-group">
                  <label for="">.</label>
                  <v-select v-model="dataSingleBuyingPurchasePaymentTax.discount_type" taggable :reduce="(option) => option.id" :options="discountTypes" label="name" item-text="name" item-value="id">
                  </v-select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="">Discount</label>
                  <input v-model="dataSingleBuyingPurchasePaymentTax.discount" type="text" class="form-control form-control-sm" placeholder="Discount" required @keypress="isNumber($event)" />
                </div>
              </div> -->
							<div class="col-md-2">
								<div class="form-group">
									<label for="">Remarks</label>
									<textarea v-model="dataSingleBuyingPurchasePaymentTax.remarks" class="form-control form-control-sm"
										placeholder="Remarks" cols="30" rows="3"></textarea>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-for="(isiDetail, d) in itemDiscountPPTax" :key="d" class="row mt-1"
					v-show="editTaxAble && parseFloat(dataSingleBuyingDownPayment.ppn) > 0 && parseInt(dataSingleBuyingDownPayment.taxable) === 1">
					<div class="col-md-3">
						<div class="form-group">
							<label for="">Discount Account</label>
							<v-select v-model="isiDetail.discountAccount" taggable :reduce="(option) => option.id"
								:options="listItemDiscount" label="item_name" @input="changeDiscPPTax(d)" item-text="item_name"
								item-value="id">
							</v-select>
						</div>
					</div>
					<div class="col-md-2">
						<div class="form-group">
							<label for="">.</label>
							<v-select v-model="isiDetail.discountType" taggable :reduce="(option) => option.id" :options="discountTypes"
								label="name" item-text="name" item-value="id">
							</v-select>
						</div>
					</div>
					<div class="col-md-2">
						<div class="form-group">
							<label for="">Discount</label>
							<input v-model="isiDetail.discount" type="number" class="form-control form-control-sm"
								placeholder="Discount" required />
						</div>
					</div>


					<div class="col-md-2">
						<a v-show="d || (!d && itemDiscountPPTax.length > 1)" href="javascript:void(0)" class="btn btn-danger btn-sm"
							@click="removeDiscountPPTax(d)" style="margin-top: 27px;"> <i class="fas fa-trash"></i>
						</a> &nbsp;
						<a v-show="d === itemDiscountPPTax.length - 1" href="javascript:void(0)" class="btn btn-create btn-sm mr-1"
							@click="addDiscountPPTax('ok')" style="margin-top: 27px;"><i class="fas fa-plus"></i></a>
					</div>
				</div>
			</div>
		</b-modal>
		<b-modal id="modalBuyingCopy" size="xl" title="Copy Transaction" style="max-width: 90% !important;"
			@ok="submitBuyingCopy" ok-title="Submit" no-close-on-backdrop :ok-disabled="isLoadingForm">
			<div class="information-dp mt-2">
				<img src="@assets/images/money-bag.png" width="24" />
				<span class="ml-2" style="color: #898989;">Cash Advance : </span>
				<span class="ml-1" style="color: #565656; font-weight: bold;">{{
					formatRupiah(this.dataBuying.downPayment.toString(),
						this.dataBalanceDP.data !== undefined ? this.dataBalanceDP.data.symbol : '') }}</span>
				<span class="ml-3" style="color: #898989;">Date : </span>
				<span class="ml-1" style="color: #565656; font-weight: bold;">{{ this.dataBuying.dateDownPayment }}</span>
			</div>
			<div style="padding: 20px;">
				<div class="row mt-3">
					<div class="col-12">
						<div class="form-row">
							<div class="col-md-2">
								<label for="">Number <span aria-required="true" class="text-danger"> * </span></label>
								<div class="input-group">
									<v-select v-show="!isManualNumberBuyingCopy" v-model="dataBuying.number" :reduce="(option) => option.id"
										:options="listAutonumber" label="name" style="width: 81%; float: left;">
									</v-select>
									<input v-show="isManualNumberBuyingCopy" v-model="dataBuying.numberText" type="text"
										class="form-control form-control-sm" placeholder="Number" required />
									<b-input-group-append style="width: 19%; float: right;">
										<b-button class="btn btn-create btn-sm" style="background: #51C1AD;"
											@click="triggerManualNumberBuyingCopy">
											<i v-show="!isManualNumberBuyingCopy" class="mdi mdi-pencil"></i>
											<i v-show="isManualNumberBuyingCopy" class="mdi mdi-close"></i>
										</b-button>
									</b-input-group-append>
								</div>
							</div>
							<!-- <div class="col-md-2">
                <div class="form-group">
                  <label for="">Vendor</label>
                  <v-select v-model="dataBuying.vendor" :options="listVendor" :reduce="(option) => option.id" label="name" @input="changeVendor">
                  </v-select>
                </div>
              </div> -->
							<div class="col-md-2">
								<div class="form-group">
									<label for="">Invoice <span aria-required="true" class="text-danger"> * </span></label>
									<input v-model="dataBuying.invoice" type="text" class="form-control form-control-sm"
										placeholder="Invoice" required />
								</div>
							</div>
							<div class="col-md-2">
								<div class="form-group">
									<label for="">Term <span aria-required="true" class="text-danger"> * </span></label>
									<v-select v-model="dataBuying.term" taggable :reduce="(option) => option.id" :options="listTerm"
										label="name" item-text="name" item-value="id" @input="changeTermBuyingCopy">
									</v-select>
								</div>
							</div>
							<div class="col-md-2">
								<div class="form-group">
									<label for="">Currency <span aria-required="true" class="text-danger"> * </span></label>
									<v-select v-model="dataBuying.currency" taggable :options="listCurrency" :reduce="(option) => option.id"
										label="code" item-text="code" item-value="id" @input="changeCurrency" disabled>
									</v-select>
								</div>
							</div>
							<div class="col-md-2">
								<label for="">Date (yyyy/mm/dd) <span aria-required="true" class="text-danger"> * </span></label>
								<date-picker v-model="dataBuying.date" :format="momentFormatBuyingCopy" type="date" lang="en"
									style="height: 31px;" :placeholder="'Select Date'" @input="getTglBuyingCopy">
								</date-picker>
							</div>
							<div class="col-md-2">
								<div class="form-group">
									<label for="">PPN <span aria-required="true" class="text-danger"> * </span></label>
									<v-select v-model="dataBuying.ppn" :reduce="(option) => option.id" :options="listPercentage"
										label="description" @input="changePpnCopy">
									</v-select>
								</div>
							</div>
							<div class="col-md-2">
								<div class="form-group">
									<label for="">PPH <span aria-required="true" class="text-danger"> * </span></label>
									<v-select v-model="dataBuying.pph" :reduce="(option) => option.id" :options="listPPH"
										label="description" @input="changePphCopy">
									</v-select>
								</div>
							</div>
							<div class="col-md-2" v-show="(dataBuying.ppn !== undefined && dataBuying.ppn > 0)">
								<div class="form-group">
									<label for="">Document Code <span aria-required="true" class="text-danger"> * </span></label>
									<v-select v-model="dataBuying.docCode" :options="listDocCodeNew" :reduce="(option) => option.code"
										label="text" @input="changeDocCodeCopy" item-text="text" item-value="code">
									</v-select>
								</div>
							</div>
							<div class="col-md-2" v-show="(dataBuying.ppn !== undefined && dataBuying.ppn > 0 && showTaxType === true)">
								<div class="form-group">
									<label for="">Tax Type <span aria-required="true" class="text-danger"> * </span></label>
									<v-select v-model="dataBuying.taxType" :options="listTaxTypeBuying" :reduce="(option) => option.code"
										label="text" item-text="text" item-value="code">
									</v-select>
								</div>
							</div>
							<div class="col-md-2" v-show="(dataBuying.docCode !== undefined && dataBuying.ppn > 0 && dataBuying.docCode === 'CTAS_IMPORT' )">
								<div class="form-group">
									<label for="">Transaction Document</label>
									<v-select v-model="dataBuying.trx_doc_type" :options="listTrxDocTypeBuying" :reduce="(option) => option.code" label="text" item-text="text" item-value="code">
									</v-select>
								</div>
							</div>

							<div class="col-md-2" v-show="showRate">
								<div class="form-group">
									<label for="">Commercial Rate <span aria-required="true" class="text-danger"> * </span></label>
									<input v-model="dataBuying.commercialRate" type="text" class="form-control form-control-sm"
										placeholder="Commercial Rate" required @keypress="isNumber($event)" />
								</div>
							</div>
							<div class="col-md-2" v-show="showRate">
								<div class="form-group">
									<label for="">Fiscal Rate <span aria-required="true" class="text-danger"> * </span></label>
									<input v-model="dataBuying.fiscalRate" type="text" class="form-control form-control-sm"
										placeholder="Fiscal Rate" required @keypress="isNumber($event)" />
								</div>
							</div>
							<!-- <div class="col-md-2">
                <div class="form-group">
                  <label for="">Attn</label>
                  <input v-model="dataBuying.pic_name" type="text" class="form-control form-control-sm" placeholder="Attn" required />
                </div>
              </div> -->
							<div class="col-md-2" v-show="(dataBuying.ppn !== undefined && dataBuying.ppn > 0)">
								<label for=""></label>
								<div class="form-check mt-2">
									<input id="inclusiveTax" v-model="dataBuying.inclusiveTax" type="checkbox" true-value="1"
										false-value="0" class="form-check-input" style="width: 15px; height: 15px;">
									<label for="inclusiveTax">Inclusive Tax</label>
								</div>
							</div>

							<div class="col-md-2" style="display: grid; align-items: center; justify-content: start;" v-show="this.companyIsUsingWorkingInstruction === 1">
								<div>
									<input id="isWiNumber" v-model="dataBuying.isWINumber" type="checkbox" true-value="1" false-value="0" class="form-check-input" style="width: 15px; height: 15px;position: relative;margin-left: 0; margin-right: 5px;">
									<label for="isWiNumber" style="margin-bottom: 4px;">
										WI Number
									</label>
								</div>
							</div>
							<div class="col-md-2" v-show="dataBuying.isWINumber.toString() === '1' && this.companyIsUsingWorkingInstruction === 1">
								<div class="form-group">
									<label for="includeTax" style="margin-bottom: 4px;">
										WI Number
									</label>
									<v-select v-model="dataBuying.wINumber" :options="listWINumber" :reduce="(option) => option.id" label="number" item-text="number" item-value="id">
									</v-select>
									<!-- <select v-model="dataBuying.wINumber" class="form-control form-control-sm" data-live-search="true" placeholder="Choose an WI Number" :disabled="false">
										<option v-for="(isi, key) in listWINumber" :key="key" :value="isi.id">
										{{isi.number}}
										</option>
									</select> -->
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="mt-3">
					<div v-for="(isiDetail, d) in itemBuying" :key="d" class="row">
						<!-- <div class="row"> -->
						<div class="col-md-2">
							<div class="form-group">
								<label for="">Jobfile <span aria-required="true" class="text-danger"> * </span></label>
								<v-select v-model="isiDetail.idJobfile" :options="listJobfile" :reduce="(option) => option.id"
									label="jobfile_no" item-text="jobfile_no" item-value="id" required>
								</v-select>
								<!-- <select v-model="isiDetail.idJobfile" class="form-control form-control-sm" data-live-search="true" placeholder="Choose an Item" required>
                  <option v-for="(isi, key) in listJobfile" :key="key" :value="isi.id">
                    {{isi.jobfile_no}}
                  </option>
                </select> -->
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group">
								<label for="">Item <span aria-required="true" class="text-danger"> * </span></label>
								<v-select v-model="isiDetail.item" :options="listItem" :reduce="(option) => option.id" label="item_name"
									item-text="item_name" item-value="id" required>
								</v-select>
								<!-- <select v-model="isiDetail.item" class="form-control form-control-sm" data-live-search="true" placeholder="Choose an Item" required>
                  <option v-for="(isi, key) in listItem" :key="key" :value="isi.id">
                    {{isi.item_name}}
                  </option>
                </select> -->
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group">
								<label for="">Qty <span aria-required="true" class="text-danger"> * </span></label>
								<input v-model="isiDetail.qty" type="text" class="form-control form-control-sm" placeholder="Qty" required
									@keypress="isNumber($event)" />
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group">
								<label for="">Price <span aria-required="true" class="text-danger"> * </span></label>
								<input v-model="isiDetail.price" type="text" class="form-control form-control-sm" placeholder="Price"
									required @keypress="isNumber($event)" />
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group">
								<label for="">Remark</label>
								<input v-model="isiDetail.remarks" type="text" class="form-control form-control-sm" placeholder="Remarks"
									required />
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group">
								<input id="isTruck" v-model="isiDetail.isTruck" type="checkbox" true-value="1" false-value="0"
									class="form-check-input"
									style="width: 15px; height: 15px;position: relative;margin-left: 0; margin-right: 5px;"
									@change="cekIsTruck(d)">
								<label for="includeTax" style="margin-bottom: 4px;">
									Department
								</label>
								<a v-show="d || (!d && itemBuying.length > 1)" href="javascript:void(0)" class="btn btn-danger btn-sm"
									@click="removeDetail(d)" style="float: right;"> <i class="fas fa-trash"></i>
								</a> &nbsp;
								<a v-show="d === itemBuying.length - 1" href="javascript:void(0)" class="btn btn-create btn-sm mr-1"
									@click="addDetail('ok')" style="float: right;"><i class="fas fa-plus"></i></a>
								<select v-show="isiDetail.isTruck === '1'" v-model="isiDetail.truck" class="form-control form-control-sm"
									data-live-search="true" placeholder="Choose an Truck" required>
									<option v-for="(isi, key) in listTruck" :key="key" :value="isi.id">
										{{ isi.truck_no }}
									</option>
								</select>
							</div>
						</div>
						<!-- <div class="col-md-1 pt-1">
              <br>
              <a v-show="d || (!d && itemBuying.length > 1)" href="javascript:void(0)" class="btn btn-danger btn-sm" @click="removeDetail(d)"> <i class="fas fa-trash"></i>
              </a> &nbsp;
              <a v-show="d === itemBuying.length - 1" href="javascript:void(0)" class="btn btn-create btn-sm" @click="addDetail('ok')"><i class="fas fa-plus"></i></a>
              &nbsp;
            </div> -->
					</div>
				</div>

				<!-- <div class="row">
          <div class="col-md-12">
            <label for=""></label>
            <div class="form-check mt-2 p-0">
              <label for="inclusiveTax">Copy Transaction</label>
              <input id="inclusiveTax" v-model="isCopyTransaction" type="checkbox" true-value="1" false-value="0" class="form-check-input ml-2" style="width: 15px; height: 15px;">
            </div>
          </div>
        </div>

        <div class="row mt-3" v-show="isCopyTransaction === '1'">
          <div class="col-12">
            <div class="form-row">
              <div class="col-md-2">
                <div class="form-group">
                  <v-select v-model="typeCopy" :options="listType" :reduce="(option) => option.id" label="name" disabled>
                  </v-select>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <v-select v-model="joborderCopy" taggable :reduce="(option) => option.id" :options="listJobOrderCopy" label="name" item-text="name" item-value="id">
                  </v-select>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <v-select v-model="numberCopy" taggable :options="listNumberCopy" :reduce="(option) => option.id" label="code" item-text="code" item-value="id">
                  </v-select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <input v-model="remarksCopy" type="text" class="form-control form-control-sm" />
                </div>
              </div>
            </div>
          </div>
        </div> -->
			</div>
		</b-modal>
		<b-modal id="modalDiscountAccounts" size="md" title="Discount Accounts" style="max-width: 90% !important;">
			<div>
				<div v-for="(isiDetail, d) in listDiscounts" :key="'C' + d" class="mt-2">
					<span>{{ isiDetail.discount_name }}</span>
				</div>
			</div>
		</b-modal>
	</div>
</template>
<script>
import Swal from 'sweetalert2'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
import { authMethods } from '@state/helpers'
import { mapActions, mapState } from 'vuex'
import axios from 'axios'
import moment from 'moment'
import DatePicker from 'vue2-datepicker';
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

var url = '/multiBuyingDownPayment'

for (
	let i = 0;
	i < JSON.parse(localStorage.getItem('userPermissions')).length;
	i++
) {
	// const element = array[i];
	// if (! JSON.parse(localStorage.getItem('userPermissions'))
	//       .includes(binding.expression
	//         .replace(/'/g, "")
	//           .replace(/"/g, ""))) {
	//      url = '/quotation';
	// }else{
	//   url = '/quotation?admin';
	// }
	// console.log(JSON.parse(localStorage.getItem('userPermissions'))[i])
	if (
		JSON.parse(localStorage.getItem('userPermissions'))[i] ===
		'CRM_ADMIN_ENABLE'
	) {
		url = '/multiBuyingDownPayment'
	}
}
console.log(url, 'url')

export default {
	components: {
		Vuetable,
		VuetablePagination,
		VuetablePaginationInfo,
		DatePicker,
		FormWizard,
		TabContent,
	},
	props: {
		showActions: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			moreParams: {},
			filterText: '',
			deleteData: {},
			isLoadingTable: false,
			httpOptions: {
				headers: {
					Authorization: 'Bearer ' + localStorage.getItem('access_token'),
				},
			},
			apiUrl: this.$store.state.rootApi + url + '/getBuyingDownPaymentList?id_job_order=0',
			urlPdf: '#',
			items: [
				{
					text: 'Master',
					href: '/',
				},
				{
					text: 'DownPayment',
					href: '/multiBuyingDownPayment',
				},
				{
					text: 'List',
					active: true,
				},
			],
			lengthMenu: [5, 10, 25, 50, 100],
			perPage: 5,
			fields: [
				{
					name: 'counting_number',
					title: 'No',
					// sortField: 'vendor_name',
				},
				{
					name: 'vendor_name',
					title: 'Vendor',
					sortField: 'vendor_name',
				},
				{
					name: 'down_payment',
					title: 'Cash Advance',
					sortField: 'down_payment',
				},
				{
					name: 'number',
					title: 'Cash Advance Number',
					sortField: 't_job_order_buying_down_payment.number',
				},
				{
					name: 'PPNumber',
					title: 'PP Number',
					sortField: 'PPNumber',
				},
				{
					name: 'PPNumber_tax',
					title: 'PP Number Tax',
					sortField: 'PPNumber_tax',
				},
				{
					name: 'payment_method',
					title: 'Payment Method',
					sortField: 'payment_method',
				},
				{
					name: 'disc_acc_name',
					title: 'Discount Account',
					sortField: 'disc_acc_name',
				},
				{
					name: 'discount',
					title: 'Discount',
					sortField: 'discount',
				},
				{
					name: 'disc_acc_name_tax',
					title: 'Discount Account Tax',
					// sortField: 'disc_acc_name_tax',
				},
				{
					name: 'amount_discount_pp_tax',
					title: 'Discount Tax',
					// sortField: 'amount_discount_pp_tax',
				},
				{
					name: 'count_pi',
					title: 'Vendor Invoice / Settlement',
					sortField: 'count_pi',
				},
				{
					name: 'status',
					title: 'Status',
					titleClass: 'text-center',
					dataClass: 'text-center',
					// sortField: 'count_pi',
				},
				{
					name: 'actions',
					title: 'Actions',
					titleClass: 'text-center',
					dataClass: 'text-center',
				},
			],
			css: {
				pagination: {
					activeClass: 'btn-primary text-white',
					pageClass: 'btn btn-sm ',
					linkClass: 'btn btn-sm',
					icons: {
						prev: 'fas fa-arrow-left',
						next: 'fas fa-arrow-right',
					},
				},
			},
			momentFormat: {
				// this.dataPayload.year
				stringify: (date) => {
					console.log(date)
					return date ? moment(this.dataBuyingDownPayment.date).format('YYYY-MM-DD') : ''
				},
			},
			momentFormatEdit: {
				// this.dataPayload.year
				stringify: (date) => {
					console.log(date)
					return date ? moment(this.dataSingleBuyingDownPayment.date).format('YYYY-MM-DD') : ''
				},
			},
			momentFormatBuyingCopy: {
				// this.dataPayload.year
				stringify: (date) => {
					console.log(date)
					return date ? moment(this.dataBuying.date).format('YYYY-MM-DD') : ''
				},
			},
			dateFormating(date) {
				return date ? moment(date).format('DD MMM YYYY') : ''
			},
			momentFormatChequeDate: {
				// this.dataPayload.year
				stringify: (date) => {
					console.log(date)
					return date ? moment(this.dataBuyingPurchasePayment.chequeDate).format('YYYY-MM-DD') : ''
				},
			},
			momentFormatEditChequeDate: {
				// this.dataPayload.year
				stringify: (date) => {
					console.log(date)
					return date ? moment(this.dataSingleBuyingPurchasePayment.cheque_date).format('YYYY-MM-DD') : ''
				},
			},
			momentFormatEditTaxChequeDate: {
				// this.dataPayload.year
				stringify: (date) => {
					console.log(date)
					return date ? moment(this.dataSingleBuyingPurchasePaymentTax.cheque_date).format('YYYY-MM-DD') : ''
				},
			},
			momentFormatPP: {
				// this.dataPayload.year
				stringify: (date) => {
					console.log(date)
					return date ? moment(this.dataBuyingPurchasePayment.date).format('YYYY-MM-DD') : ''
				},
			},
			momentFormatPPTax: {
				// this.dataPayload.year
				stringify: (date) => {
					console.log(date)
					return date ? moment(this.dataBuyingPurchasePaymentTax.date).format('YYYY-MM-DD') : ''
				},
			},
			momentFormatPPEdit: {
				// this.dataPayload.year
				stringify: (date) => {
					console.log(date)
					return date ? moment(this.dataSingleBuyingPurchasePayment.date).format('YYYY-MM-DD') : ''
				},
			},
			momentFormatPPTaxEdit: {
				// this.dataPayload.year
				stringify: (date) => {
					console.log(date)
					return date ? moment(this.dataSingleBuyingPurchasePaymentTax.date).format('YYYY-MM-DD') : ''
				},
			},
			dataBuyingDownPayment: {
				currency: '',
				term: '',
				number: '',
				numberText: '',
				invoice: '',
				vendor: '',
				date: '',
				ppn: '',
				docCode: '',
				taxType: '',
				commercialRate: '',
				fiscalRate: '',
				inclusiveTax: 0,
				downPayment: 0,
				taxable: 0,
				trx_doc_type: '',
				idPercentage: '',
				isWINumber: 1,
        		wINumber: '',
			},
			dataBuyingPurchasePayment: {
				project: '',
				currency: '',
				bank: '',
				number: '',
				numberText: '',
				paymentMethod: '',
				vendor: '',
				date: '',
				discountAccount: '',
				discount: 0,
				remarks: '',
				commercialRate: '',
				fiscalRate: '',
				discountType: '',
				// inclusiveTax: 0,
				currencyBank: '',
				amount: 0,
				taxable: 0,
				departemen: ''
			},
			dataBuyingPurchasePaymentTax: {
				currency: '',
				bank: '',
				number: '',
				numberText: '',
				paymentMethod: '',
				vendor: '',
				date: '',
				discountAccount: '',
				discount: 0,
				remarks: '',
				commercialRate: '',
				fiscalRate: '',
				discountType: '',
				// inclusiveTax: 0,
				currencyBank: '',
				amount: 0,
				taxable: 0
			},
			showRate: false,
			showRatePP: false,
			showRatePPTax: false,
			isManualNumberBuyingDownPayment: false,
			isManualNumberBuyingPurchasePayment: false,
			isManualNumberBuyingPurchasePaymentTax: false,
			isManualNumberBuyingDownPaymentEdit: false,
			isManualNumberBuyingPurchasePaymentEdit: false,
			isManualNumberBuyingPurchasePaymentTaxEdit: false,
			isManualNumberBuying: false,
			isManualNumberBuyingCopy: false,
			isTruck: false,
			isCopyTransaction: "0",
			inclusiveTax: 0,
			showTaxType: true,
			statusModal: '',
			paymentMethods: [
				{
					id: "CASH_OTHER",
					name: "Cash/Other"
				},
				{
					id: "BANK_CHEQUE",
					name: "Cheque/Clearing"
				},
				{
					id: "BANK_TRANSFER",
					name: "Transfer Bank"
				},
				{
					id: "QRIS",
					name: "QRIS"
				},
				{
					id: "PAYMENT_LINK",
					name: "Payment Link"
				},
				{
					id: "VIRTUAL_ACCOUNT",
					name: "Virtual Account"
				},
			],
			dataBuying: {
				currency: '',
				term: '',
				number: '',
				numberText: '',
				invoice: '',
				vendor: '',
				date: '',
				ppn: '',
				docCode: '',
				taxType: '',
				commercialRate: '',
				fiscalRate: '',
				inclusiveTax: false,
				idDownPayment: 0,
				downPayment: 0,
				dateDownPayment: '',
				trx_doc_type: '',
				idPercentage: '',
				isWINumber: 1,
        		wINumber: '',
			},
			itemBuying: [{
				item: '',
				price: '',
				qty: '',
				remarks: '',
				truck: '',
				isTruck: 0,
				idJobfile: 0,
			}],
			discountTypes: [{
				id: 2,
				name: "%"
			},
			{
				id: 3,
				name: "Nominal"
			}],
			isEnableTabTax: false,
			editTaxAble: false,
			isLoadingForm: false,
			pageActive: 1,
			itemDiscountPP: [{
				discountAccount: '',
				discountType: '',
				discount: '',
			}],
			itemDiscountPPTax: [{
				discountAccount: '',
				discountType: '',
				discount: '',
			}],
			listDiscounts: [],
			listItemBankIDR: [],
			hideFinishedCA: false
		}
	},
	computed: {
		...mapState('port', ['listPort']),
		...mapState('vendor', ['listVendor', 'dataVendor']),
		...mapState('truck', ['listTruck']),
		...mapState('autonumber', ['listAutonumber', 'listAutonumberPP', 'listAutonumberPPTax']),
		...mapState('currency', ['listCurrency', 'dataCurrency', 'dataDefaultCurrency', 'kursData']),
		...mapState('item', ['listItem', 'listItemNonInventory', 'listItemBank', 'dataItem', 'listItemDiscount']),
		...mapState('term', ['listTerm']),
		...mapState('company', ['companyIsAol', 'companyIsUsingPPH', 'companyIsUsingWorkingInstruction']),
		...mapState('multiBuyingDownPayment', ['listBuyingDownPayment', 'dataSingleBuyingDownPayment', 'dataSingleBuyingPurchasePayment', 'dataSingleBuyingPurchasePaymentTax', 'dataBalanceDP']),
		...mapState('jobOrderBuying', ['listDocCode', 'listTaxTypeBuying', 'listDocCodeNew', 'listTrxDocTypeBuying']),
		...mapState('percentage', ['listPercentage', 'listPPH']),
		...mapState('multiPurchaseInvoice', ['listWINumber']),
		...mapState('jobfile', ['listJobfile']),
	},
	mounted() {
		this.getListPort()
		this.getListVendor()
		this.p_getDefaultCurrency()
		this.initCurrency()
		this.getItemNonInventoryDiffVar()
		this.getListJobfile()
		this.getListWINumber()
		// this.getListTerm()
		// this.getListItem()
		this.getListPercentage()
		this.getListPPH()
		this.getListTruck()
		// let idJoborder = window.location.href.split("/").splice(0, 6)[5];
		this.getListBuyingDownPayment(null)
		this.isAol();
		this.isUsingPPH();
		this.isUsingWorkingInstruction();
		this.getItemByCategoryBank({
			itemCategory: 3
		})
			.then((res) => {
				// Ini buat filter item yg currency nyaa IDR --> karna untuk PP Tax itu harus IDR
				res.forEach((dr) => {
					if (dr.currency_is_active === 1 && dr.currency === 0) {
						this.listItemBankIDR.push(dr)
					}
				})
			})
		this.getItemForDiscount()
	},
	methods: {
		...authMethods,
		loginByToken() {
			console.log('login by token')
		},
		...mapActions('port', ['getListPort']),
		...mapActions('vendor', ['getListVendor', 'getVendorById']),
		...mapActions('truck', ['getListTruck']),
		...mapActions('autonumber', ['getListAutonumber', 'getListAutonumberFlexmod', 'getListAutonumberPP', 'getListAutonumberPPTax']),
		...mapActions('currency', ['getListCurrency', 'getDefaultCurrency', 'getKurs', 'getKursWithDate']),
		...mapActions('item', ['getItemByPpn', 'getItemByCategory', 'getItemNonInventoryDiffVar', 'getItemByCategoryBank', 'getItem', 'getItemForDiscount', 'getItemByTax']),
		...mapActions('term', ['getListTerm']),
		// ...mapActions('quotation', ['saveQuotation']),
		...mapActions('percentage', ['getListPercentage', 'getListPPH']),
		...mapActions('company', ['isAol', 'isUsingPPH', 'isUsingWorkingInstruction']),
		...mapActions('jobfile', ['getListJobfile']),
		...mapActions('multiPurchaseInvoice', ['getListWINumber']),
		// ...mapActions('quotation', ['deleteQuotation', 'generatePdf']),
		// ...mapActions('favoriteJobOrder', ['saveNewFavorite', 'loadFavorite', 'deleteFavJobOrder']),
		...mapActions('multiBuyingDownPayment', ['saveBuyingDownPayment', 'getListBuyingDownPayment', 'getBuyingDownPayment', 'getBuyingPurchasePayment', 'getBuyingPurchasePaymentTax', 'getBuyingPurchasePaymentTaxDefault', 'updateBuyingDownPayment', 'deleteBuyingDownPayment', 'submitAOL', 'submitAOLPP', 'submitAOLPPTax', 'saveBuyingCopy', 'deleteBuyingDownPaymentWithBuying', 'getBalanceDP', 'finishDownPayment', 'hardDeleteBuyingDownPayment']),
		...mapActions('jobOrderBuying', ['getListDocCodeDP', 'getListTaxTypeBuyingDP', 'getListDocCode', 'getListTaxTypeBuying', 'getListBuying','getListDocCodeNew', 'getListTrxDocTypeBuying']),
		doFilter() {
			this.onFilterSet(this.filterText)
		},
		/**
		 * Table Length Change
		 *
		 * @return  {[type]}  [return description]
		 */
		onChangeTableLength() {
			// this.$refs.vuetable.reload()
			this.$nextTick(() => this.$refs.vuetable.refresh())
		},
		/**
		 * Pagination page change
		 */
		onChangePage(page) {
			this.$refs.vuetable.changePage(page)
			this.pageActive = page
		},
		/**
		 * Pagination info and pagination show
		 */
		onPaginationData(paginationData) {
			this.$refs.pagination.setPaginationData(paginationData)
			this.$refs.paginationInfo.setPaginationData(paginationData)
		},
		/**
		 * [onFilterSet description]
		 *
		 * @param   {[type]}  filterText  [filterText description]
		 *
		 * @return  {[type]}              [return description]
		 */
		onFilterSet(filterText) {
			this.moreParams = {
				search: filterText,
			}
			this.$nextTick(() => this.$refs.vuetable.refresh())
		},
		/**
		 * Show Table Loading
		 *
		 * @return  {[type]}  [return description]
		 */
		loadingTable() {
			this.isLoadingTable = true
		},

		/**
		 * Hide Loading if Success
		 *
		 * @return  {[type]}  [return description]
		 */
		loadTableSuccess() {
			this.isLoadingTable = false
		},
		/**
		 * Hide Loading if Failed
		 *
		 * @return  {[type]}  [return description]
		 */
		loadTableFailed(error) {
			console.log(error)

			this.isLoadingTable = false
			this.$swal({
				title: 'Terjadi Kesalahan',
				type: 'error',
				timer: '2000',
				toast: true,
				position: 'bottom-end',
			})
			// }
		},
		showConfirmDelete(id) {
			this.deleteData.id = id
			let self = this
			// this.$swal({
			// 	title: 'Are you sure?',
			// 	text: 'You will not be able to recover this!',
			// 	type: 'warning',
			// 	showCancelButton: true,
			// 	confirmButtonText: 'Yes, delete it!',
			// 	cancelButtonText: 'No, keep it',
			// }).then((result) => {
			// 	if (result.value) {
			// 		this.$swal('Deleted!', 'Your data has been deleted.', 'error')
			// 		self.onDelete()
			// 	} else if (result.dismiss) {
			// 		this.$swal('Cancelled', 'Your data is safe :)', 'error')
			// 	}
			// })
			Swal.fire({
				title: 'Are you sure?',
				text: 'You will not be able to recover this data!',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: 'rgb(221, 107, 85)',
				cancelButtonColor: '#C1C1C1',
				reverseButtons: true,
				confirmButtonText: 'Yes, delete it!',
			}).then((result) => {
				if (result.isConfirmed) {
					self.onDelete()
					Swal.fire('Deleted!', 'Your file has been deleted.', 'success')
				}
			})
		},
		/**
		 * Delete Brand
		 *
		 * @return  {[type]}  [return description]
		 */
		onDelete() {
			// this.isLoadingTable = true
			this.deleteQuotation(this.deleteData.id)
				.then((res) => {
					this.successAlert()
					// this.isLoadingTable = false;
					this.$refs.vuetable.refresh()
				})
				.catch((err) => {
					this.errorAlert()
					console.log(err.response.message)

					// this.isLoadingTable = false;
				})
		},
		openModalBuyingDownPayment() {
			this.getListJobfile()
			this.getListDocCodeDP()
			this.listAutonumber = []
			this.getListAutonumber(31)
			this.getListAutonumberPP(32)
			this.getListAutonumberPPTax(35)
			this.getListWINumber()
			console.log('OKEEE')
			// this.dataBuyingDownPayment = {
			//   currency: '',
			//   term: '',
			//   number: '',
			//   numberText: '',
			//   po: '',
			//   customer: '',
			//   date: '',
			//   ppn: '',
			//   docCode: '',
			//   taxType: '',
			//   commercialRate: '',
			//   fiscalRate: '',
			//   inclusiveTax: false
			// }

			this.dataBuyingDownPayment.term = ''
			this.dataBuyingDownPayment.number = ''
			this.dataBuyingDownPayment.numberText = ''
			this.dataBuyingDownPayment.invoice = ''
			this.dataBuyingDownPayment.vendor = ''
			this.dataBuyingDownPayment.date = ''
			this.dataBuyingDownPayment.ppn = ''
			this.dataBuyingDownPayment.docCode = ''
			this.dataBuyingDownPayment.taxType = ''
			this.dataBuyingDownPayment.commercialRate = ''
			this.dataBuyingDownPayment.fiscalRate = ''
			this.dataBuyingDownPayment.downPayment = 0
			this.dataBuyingDownPayment.taxable = '0'
			this.dataBuyingDownPayment.description = ''
			this.dataBuyingDownPayment.inclusiveTax = false
			this.dataBuyingDownPayment.trx_doc_type = ''
			this.dataBuyingDownPayment.idPercentage = ''
			this.showRate = false
			this.statusModal = 'createDp'

			this.itemBuying = [
				{
					item: '',
					price: '',
					qty: '',
					itemDescription: '',
					truck: '',
					isTruck: 0,
					idJobfile: '',
				}
			]

			this.itemDiscountPP = [
				{
					discountAccount: '',
					discountType: '',
					discount: '',
				}
			]

			this.itemDiscountPPTax = [
				{
					discountAccount: '',
					discountType: '',
					discount: '',
				}
			]

			this.dataBuyingPurchasePayment = {
				currency: '',
				bank: '',
				number: '',
				numberText: '',
				paymentMethod: '',
				vendor: '',
				date: '',
				discountAccount: '',
				discount: 0,
				remarks: '',
				commercialRate: '',
				fiscalRate: '',
				discountType: '',
				// inclusiveTax: 0,
				currencyBank: '',
				amount: 0,
				taxable: 0
			}

			this.dataBuyingPurchasePaymentTax = {
				currency: '',
				bank: '',
				number: '',
				numberText: '',
				paymentMethod: '',
				vendor: '',
				date: '',
				discountAccount: '',
				discount: 0,
				remarks: '',
				commercialRate: '',
				fiscalRate: '',
				discountType: '',
				// inclusiveTax: 0,
				currencyBank: '',
				amount: 0,
				taxable: 0
			}

			this.$bvModal.show('modalBuyingDownPayment')
		},
		getTgl() {
			this.dataBuyingDownPayment.date = moment(this.dataBuyingDownPayment.date).format('YYYY-MM-DD')
			let year = moment(this.dataBuyingDownPayment.date).year()
      this.dataBuyingDownPayment.docCode = ''
      this.changeDocCode()
      if(year === 2024){
        this.getListDocCodeNew(1)
      }else{
        this.getListDocCodeNew(2)
      }
		},
		getTglChequeDate() {
			this.dataBuyingPurchasePayment.chequeDate = moment(this.dataBuyingPurchasePayment.chequeDate).format('YYYY-MM-DD')
		},
		getTglEditChequeDate() {
			this.dataSingleBuyingPurchasePayment.cheque_date = moment(this.dataSingleBuyingPurchasePayment.cheque_date).format('YYYY-MM-DD')
		},
		getTglEditTaxChequeDate() {
			this.dataSingleBuyingPurchasePaymentTax.cheque_date = moment(this.dataSingleBuyingPurchasePaymentTax.cheque_date).format('YYYY-MM-DD')
		},
		getTglPP() {
			this.dataBuyingPurchasePayment.date = moment(this.dataBuyingPurchasePayment.date).format('YYYY-MM-DD')
		},
		getTglPPTax() {
			this.dataBuyingPurchasePaymentTax.date = moment(this.dataBuyingPurchasePaymentTax.date).format('YYYY-MM-DD')
		},
		getTglPPEdit() {
			this.dataSingleBuyingPurchasePayment.date = moment(this.dataSingleBuyingPurchasePayment.date).format('YYYY-MM-DD')
		},
		getTglPPTaxEdit() {
			this.dataSingleBuyingPurchasePaymentTax.date = moment(this.dataSingleBuyingPurchasePaymentTax.date).format('YYYY-MM-DD')
		},
		getTglEdit() {
			this.dataSingleBuyingDownPayment.date = moment(this.dataSingleBuyingDownPayment.date).format('YYYY-MM-DD')
			let year = moment(this.dataSingleBuyingDownPayment.date).year()
      this.dataSingleBuyingDownPayment.doc_code = ''
      this.changeDocCodeCopy()
      if(year === 2024){
        this.getListDocCodeNew(1)
      }else{
        this.getListDocCodeNew(2)
      }
		},
		getTglBuyingCopy() {
			this.changeCurrency(null)
			this.dataBuying.date = moment(this.dataBuying.date).format('YYYY-MM-DD')
			let year = moment(this.dataBuying.date).year()
      this.dataBuying.docCode = ''
      this.changeDocCodeCopy()
      if(year === 2024){
        this.getListDocCodeNew(1)
      }else{
        this.getListDocCodeNew(2)
      }
		},
		initCurrency() {
			try {
				this.getListCurrency()
					.then((res) => {
						// this.dataBuyingDownPayment.currency = 2
						// console.log(this.dataDefaultCurrency, 'This Data Default')
					})
					.catch((err) => {
						console.log(err)
					})
			} catch (error) {
				console.log(error)
			}
		},
		p_getDefaultCurrency() {
			try {
				this.getDefaultCurrency()
					.then((res) => {
						this.dataBuyingDownPayment.currency = this.dataDefaultCurrency.id
						// console.log(this.dataDefaultCurrency, 'This Data Default')
					})
					.catch((err) => {
						console.log(err)
					})
			} catch (error) {
				console.log(error)
			}
		},
		submitBuying(bvModalEvent) {
			bvModalEvent.preventDefault()
			console.log(this.dataBuyingDownPayment, 'THIS DATA Selling')
			console.log(this.itemBuying, 'THIS DATA ITEM Selling')
			console.log('OKEE')

			if (this.dataBuyingDownPayment.number === "" && this.dataBuyingDownPayment.numberText === "") {
				Swal.fire({
					title: "Failed!",
					text: "Please insert field Number",
					icon: 'warning',
					// showCancelButton: true,
					confirmButtonColor: 'rgb(221, 107, 85)',
					// cancelButtonColor: '#C1C1C1',
					// reverseButtons: true,
					confirmButtonText: 'Ok'
				})

				return false;
			}

			if (this.dataBuyingDownPayment.vendor === "") {
				Swal.fire({
					title: "Failed!",
					text: "Please insert field Vendor",
					icon: 'warning',
					// showCancelButton: true,
					confirmButtonColor: 'rgb(221, 107, 85)',
					// cancelButtonColor: '#C1C1C1',
					// reverseButtons: true,
					confirmButtonText: 'Ok'
				})

				return false;
			}

			if (this.dataBuyingDownPayment.invoice === "") {
				Swal.fire({
					title: "Failed!",
					text: "Please insert field Invoice No",
					icon: 'warning',
					// showCancelButton: true,
					confirmButtonColor: 'rgb(221, 107, 85)',
					// cancelButtonColor: '#C1C1C1',
					// reverseButtons: true,
					confirmButtonText: 'Ok'
				})

				return false;
			}

			if (this.dataBuyingDownPayment.date === "") {
				Swal.fire({
					title: "Failed!",
					text: "Please insert field Date",
					icon: 'warning',
					// showCancelButton: true,
					confirmButtonColor: 'rgb(221, 107, 85)',
					// cancelButtonColor: '#C1C1C1',
					// reverseButtons: true,
					confirmButtonText: 'Ok'
				})

				return false;
			}

			// this.dataBuyingDownPayment.currency = this.dataDefaultCurrency.id

			if (this.dataBuyingDownPayment.currency !== this.dataDefaultCurrency.id) {
				if (this.dataBuyingDownPayment.commercialRate === "") {
					Swal.fire({
						title: "Failed!",
						text: "Please insert field Commercial Rate",
						icon: 'warning',
						// showCancelButton: true,
						confirmButtonColor: 'rgb(221, 107, 85)',
						// cancelButtonColor: '#C1C1C1',
						// reverseButtons: true,
						confirmButtonText: 'Ok'
					})

					return false
				}
				if (this.dataBuyingDownPayment.fiscalRate === "") {
					Swal.fire({
						title: "Failed!",
						text: "Please insert field Fiscal Rate",
						icon: 'warning',
						// showCancelButton: true,
						confirmButtonColor: 'rgb(221, 107, 85)',
						// cancelButtonColor: '#C1C1C1',
						// reverseButtons: true,
						confirmButtonText: 'Ok'
					})

					return false
				}
			}

			if (parseInt(this.dataBuyingDownPayment.taxable) === 0) {
				this.dataBuyingDownPayment.ppn = 0
				this.dataBuyingDownPayment.inclusiveTax = 'false'
			}

			let idJoborder = window.location.href.split("/").splice(0, 6)[5];

			let mergeData = {
				id_joborder: idJoborder,
				id_default: this.dataDefaultCurrency.id,
				buying: this.dataBuyingDownPayment,
				itemBuying: this.itemBuying
			}

			console.log(mergeData, 'THIS MERGE DATA');

			let headers = {
				'Content-Type': 'application/json',

			}

			this.saveBuyingDownPayment({
				payload: mergeData,
				headers: headers
			})
				.then((res) => {
					if (res.data.status) {
						const Toast = Swal.mixin({
							toast: true,
							position: 'bottom-end',
							showConfirmButton: false,
							timer: 2000,
							timerProgressBar: true,
							onOpen: (toast) => {
								toast.addEventListener('mouseenter', Swal.stopTimer)
								toast.addEventListener('mouseleave', Swal.resumeTimer)
							}
						})

						Toast.fire({
							icon: 'success',
							title: 'successfully'
						})

						this.$nextTick(() => {
							this.$bvModal.hide('modalBuyingDownPayment')
						})

						this.$nextTick(() => this.$refs.vuetable.refresh())
					} else {
						Swal.fire({
							title: "Failed!",
							text: res.data.message,
							icon: 'warning',
							confirmButtonColor: 'rgb(221, 107, 85)',
							confirmButtonText: 'Ok'
						})
					}
				})
				.catch((err) => {
					Swal.fire({
						title: "Failed!",
						text: err.response.data.message,
						icon: 'warning',
						confirmButtonColor: 'rgb(221, 107, 85)',
						confirmButtonText: 'Ok'
					})
				})
		},
		triggerManualNumberBuying() {
			this.isManualNumberBuyingDownPayment = !this.isManualNumberBuyingDownPayment
		},
		triggerManualNumberBuyingCopy() {
			this.isManualNumberBuyingCopy = !this.isManualNumberBuyingCopy
		},
		triggerManualNumberBuyingPurchasePayment() {
			this.isManualNumberBuyingPurchasePayment = !this.isManualNumberBuyingPurchasePayment
		},
		triggerManualNumberBuyingPurchasePaymentTax() {
			this.isManualNumberBuyingPurchasePaymentTax = !this.isManualNumberBuyingPurchasePaymentTax
		},
		triggerManualNumberBuyingDownPaymentEdit() {
			this.isManualNumberBuyingDownPaymentEdit = !this.isManualNumberBuyingDownPaymentEdit
		},
		triggerManualNumberBuyingPurchasePaymentEdit() {
			this.isManualNumberBuyingPurchasePaymentEdit = !this.isManualNumberBuyingPurchasePaymentEdit
		},
		triggerManualNumberBuyingPurchasePaymentTaxEdit() {
			this.isManualNumberBuyingPurchasePaymentTaxEdit = !this.isManualNumberBuyingPurchasePaymentTaxEdit
		},
		cekIsTruck(idx) {
			// if(this.itemBuying[idx].isTruck) {
			//   this.itemBuying[idx].isTruck = false
			// } else {
			//   this.itemBuying[idx].isTruck = true
			// }

			console.log(this.itemBuying[idx].isTruck)
		},
		changeVendor() {
			let getVendor = this.listVendor.find(x => x.id === this.dataBuyingDownPayment.vendor)
			this.getListTerm()
				.then((res) => {
					if (getVendor !== undefined) {
						this.dataBuyingDownPayment.term = parseInt(getVendor.id_term)
					}
				})
				.then(() => {
					this.getVendorById(this.dataBuyingDownPayment.vendor)
						.then(() => {
							this.dataBuyingDownPayment.currency = this.dataVendor.id_currency
							this.changeCurrency(this.dataBuyingDownPayment.currency)
						})
				})
		},
		changeVendorCopy() {
			let getVendor = this.listVendor.find(x => x.id === this.dataBuyingCopy.customer)
			// this.dataBuyingDownPayment.term = this.dataBuyingDownPayment.customer.id_term
			this.getListTerm()
				.then((res) => {
					if (getVendor !== undefined) {
						this.dataBuyingCopy.term = parseInt(getVendor.id_term)
					}
					// this.dataBuyingDownPayment.term = this.dataBuyingDownPayment.customer.id_term
					// let termDefault = this.listTerm.find(x => x.id === this.dataBuyingDownPayment.customer.id_term)

					// if(termDefault !== undefined) {
					//   this.dataBuyingDownPayment.term = termDefault
					// }
				})
		},
		changePpn(idPercentage, option) {
			console.log(parseInt(this.dataSingleBuyingDownPayment.ppn))
			console.log(option.id_percentage);
			if (parseInt(this.dataBuyingDownPayment.ppn) > 0) {
				this.showTaxType = true;
			} else {
				this.showTaxType = false;
			}


			this.dataBuyingDownPayment.idPercentage = option.id_percentage
			this.dataSingleBuyingDownPayment.id_percentage = option.id_percentage
			this.getItemByPpn({
				ppn: this.dataBuyingDownPayment.ppn,
				itemCategory: 1
			})
		},
		changePpnCopy() {
			if (parseInt(this.dataBuying.ppn) > 0) {
				this.showTaxType = true;
			} else {
				this.showTaxType = false;
			}

			if (this.companyIsUsingPPH === 1) {
				console.log('OKEE')
				if (this.dataBuying.ppn !== "" && this.dataBuying.ppn !== undefined && this.dataBuying.ppn !== null && this.dataBuying.pph !== "" && this.dataBuying.pph !== undefined && this.dataBuying.pph !== null) {
					this.getItemByTax({
						ppn: this.dataBuying.ppn,
						pph: this.dataBuying.pph === undefined ? 0 : this.dataBuying.pph,
						itemCategory: 1
					})
				} else {
					// Reset PPN
					this.getItemByPpn({
						ppn: 0,
						itemCategory: 234353465
					})
				}
			} else {
				this.getItemByPpn({
					ppn: this.dataBuying.ppn,
					itemCategory: 1
				})
			}
		},
		changePphCopy() {
			if (parseInt(this.dataBuying.ppn) > 0) {
				this.showTaxType = true;
			} else {
				this.showTaxType = false;
			}

			if (this.companyIsUsingPPH === 1) {
				if (this.dataBuying.ppn !== "" && this.dataBuying.ppn !== undefined && this.dataBuying.ppn !== null && this.dataBuying.pph !== "" && this.dataBuying.pph !== undefined && this.dataBuying.pph !== null) {
					this.getItemByTax({
						ppn: this.dataBuying.ppn,
						pph: this.dataBuying.pph === undefined ? 0 : this.dataBuying.pph,
						itemCategory: 1
					})
				} else {
					// Reset PPN
					this.getItemByPpn({
						ppn: 0,
						itemCategory: 234353465
					})
				}
			} else {
				this.getItemByPpn({
					ppn: this.dataBuying.ppn,
					itemCategory: 1
				})
			}

		},
		changeDocCode() {
			if (this.dataBuyingDownPayment.docCode === 'DIGUNGGUNG' || this.dataBuyingDownPayment.docCode === 'PMSE' || this.dataBuyingDownPayment.docCode === 'SKP') {
				this.showTaxType = false;
			} else {
				this.showTaxType = true;
			}


			this.dataBuyingDownPayment.taxType = ''
			this.getListTaxTypeBuyingDP(this.dataBuyingDownPayment.docCode)
			this.getListTrxDocTypeBuying(this.dataBuyingDownPayment.docCode)
		},
		changeDisc() {
			this.getItem(this.dataBuyingPurchasePayment.discountAccount)
				.then((res) => {
					if (this.dataItem.diskon_type === 0) {
						this.dataBuyingPurchasePayment.discountType = ''
					} else {
						this.dataBuyingPurchasePayment.discountType = this.dataItem.diskon_type
					}
					this.dataBuyingPurchasePayment.discount = this.dataItem.diskon
					// if(this.dataItem.diskon_type === 2) {
					//   this.dataBuyingPurchasePayment.discount = parseInt(this.dataBuyingDownPayment.downPayment) * parseFloat(this.dataItem.diskon) / 100
					// } else {
					//   this.dataBuyingPurchasePayment.discount = this.dataItem.diskon
					// }
				})
		},
		changeDiscPP(index) {
			this.getItem(this.itemDiscountPP[index].discountAccount)
				.then((res) => {
					if (this.dataItem.diskon_type === 0) {
						this.itemDiscountPP[index].discountType = ''
					} else {
						this.itemDiscountPP[index].discountType = this.dataItem.diskon_type
					}
					this.itemDiscountPP[index].discount = this.dataItem.diskon
				})
		},
		changeDiscPPTax(index) {
			this.getItem(this.itemDiscountPPTax[index].discountAccount)
				.then((res) => {
					if (this.dataItem.diskon_type === 0) {
						this.itemDiscountPPTax[index].discountType = ''
					} else {
						this.itemDiscountPPTax[index].discountType = this.dataItem.diskon_type
					}
					this.itemDiscountPPTax[index].discount = this.dataItem.diskon
				})
		},
		changeDiscTax() {
			this.getItem(this.dataBuyingPurchasePaymentTax.discountAccount)
				.then((res) => {
					if (this.dataItem.diskon_type === 0) {
						this.dataBuyingPurchasePaymentTax.discountType = ''
					} else {
						this.dataBuyingPurchasePaymentTax.discountType = this.dataItem.diskon_type
					}
					this.dataBuyingPurchasePaymentTax.discount = this.dataItem.diskon
					// if(this.dataItem.diskon_type === 2) {
					//   this.dataBuyingPurchasePayment.discount = parseInt(this.dataBuyingDownPayment.downPayment) * parseFloat(this.dataItem.diskon) / 100
					// } else {
					//   this.dataBuyingPurchasePayment.discount = this.dataItem.diskon
					// }
				})
		},
		changeBank() {
			this.getItem(this.dataBuyingPurchasePayment.bank)
				.then((res) => {
					if (this.dataItem.currency_is_active === 1) {
						this.dataBuyingPurchasePayment.currencyBank = this.dataItem.currency

						if (this.dataBuyingDownPayment.currency !== this.dataBuyingPurchasePayment.currencyBank) {
							this.showRatePP = true
						} else {
							this.showRatePP = false
						}
					} else {
						this.dataBuyingPurchasePayment.currencyBank = this.dataBuyingDownPayment.currency
						this.showRatePP = false
					}
					// if(this.dataItem.diskon_type === 2) {
					//   this.dataBuyingPurchasePayment.discount = parseInt(this.dataBuyingDownPayment.downPayment) * parseFloat(this.dataItem.diskon) / 100
					// } else {
					//   this.dataBuyingPurchasePayment.discount = this.dataItem.diskon
					// }
				})
		},
		changeBankTax() {
			this.getItem(this.dataBuyingPurchasePaymentTax.bank)
				.then((res) => {
					if (this.dataItem.currency_is_active === 1) {
						this.dataBuyingPurchasePaymentTax.currencyBank = this.dataItem.currency

						// if(this.dataBuyingDownPayment.currency !== this.dataBuyingPurchasePaymentTax.currencyBank) {
						//   this.showRatePP = true
						// } else {
						//   this.showRatePP = false
						// }
					} else {
						this.dataBuyingPurchasePaymentTax.currencyBank = this.dataBuyingDownPayment.currency
						// this.showRatePP = false
					}
					// if(this.dataItem.diskon_type === 2) {
					//   this.dataBuyingPurchasePayment.discount = parseInt(this.dataBuyingDownPayment.downPayment) * parseFloat(this.dataItem.diskon) / 100
					// } else {
					//   this.dataBuyingPurchasePayment.discount = this.dataItem.diskon
					// }
				})
		},
		changeBankEdit() {
			this.getItem(this.dataSingleBuyingPurchasePayment.bank)
				.then((res) => {
					this.dataSingleBuyingPurchasePayment.price_kurs = 0
					this.dataSingleBuyingPurchasePayment.fiscal_kurs = 0
					if (this.dataItem.currency_is_active === 1) {
						this.dataSingleBuyingPurchasePayment.id_currency_bank = this.dataItem.currency

						if (this.dataBuyingDownPayment.id_currency !== this.dataSingleBuyingPurchasePayment.id_currency_bank) {
							this.showRatePP = true
						} else {
							this.showRatePP = false
						}
					} else {
						this.dataSingleBuyingPurchasePayment.id_currency_bank = this.dataBuyingDownPayment.id_currency
						this.showRatePP = false
					}
					// if(this.dataItem.diskon_type === 2) {
					//   this.dataBuyingPurchasePayment.discount = parseInt(this.dataBuyingDownPayment.downPayment) * parseFloat(this.dataItem.diskon) / 100
					// } else {
					//   this.dataBuyingPurchasePayment.discount = this.dataItem.diskon
					// }
				})
		},
		changeDiscEdit() {
			this.getItem(this.dataSingleBuyingPurchasePayment.discount_account)
				.then((res) => {
					if (this.dataItem.diskon_type === 0) {
						this.dataSingleBuyingPurchasePayment.discount_type = ''
					} else {
						this.dataSingleBuyingPurchasePayment.discount_type = this.dataItem.diskon_type
					}
					this.dataSingleBuyingPurchasePayment.discount = this.dataItem.diskon
					// if(this.dataItem.diskon_type === 2) {
					//   this.dataBuyingPurchasePayment.discount = parseInt(this.dataBuyingDownPayment.downPayment) * parseFloat(this.dataItem.diskon) / 100
					// } else {
					//   this.dataBuyingPurchasePayment.discount = this.dataItem.diskon
					// }
				})
			// this.getItem(this.dataSingleBuyingPurchasePayment.discount_account)
			//  .then((res) => {
			//   if(this.dataItem.diskon_type === 2) {
			//     this.dataSingleBuyingPurchasePayment.discount = parseInt(this.dataSingleBuyingDownPayment.down_payment) * parseFloat(this.dataItem.diskon) / 100
			//   } else {
			//     this.dataSingleBuyingPurchasePayment.discount = this.dataItem.diskon
			//   }
			//  })
		},
		changeDiscTaxEdit() {
			this.getItem(this.dataSingleBuyingPurchasePaymentTax.discount_account)
				.then((res) => {
					if (this.dataItem.diskon_type === 0) {
						this.dataSingleBuyingPurchasePaymentTax.discount_type = ''
					} else {
						this.dataSingleBuyingPurchasePaymentTax.discount_type = this.dataItem.diskon_type
					}
					this.dataSingleBuyingPurchasePaymentTax.discount = this.dataItem.diskon
					// if(this.dataItem.diskon_type === 2) {
					//   this.dataBuyingPurchasePayment.discount = parseInt(this.dataBuyingDownPayment.downPayment) * parseFloat(this.dataItem.diskon) / 100
					// } else {
					//   this.dataBuyingPurchasePayment.discount = this.dataItem.diskon
					// }
				})
			// this.getItem(this.dataSingleBuyingPurchasePayment.discount_account)
			//  .then((res) => {
			//   if(this.dataItem.diskon_type === 2) {
			//     this.dataSingleBuyingPurchasePayment.discount = parseInt(this.dataSingleBuyingDownPayment.down_payment) * parseFloat(this.dataItem.diskon) / 100
			//   } else {
			//     this.dataSingleBuyingPurchasePayment.discount = this.dataItem.diskon
			//   }
			//  })
		},
		changeDocCodeCopy() {
			if (this.dataBuying.docCode === 'DIGUNGGUNG') {
				this.showTaxType = false;
			} else {
				this.showTaxType = true;
			}

			if(this.dataBuying.docCode === ''){
        this.showTaxType = false;
        this.showTaxDetail = false;
        this.showTaxInput = false;
        this.dataBuying.taxDate = ''
        this.dataBuying.taxNo = ''
        this.isTaxDateChecked = 0
      }

			this.dataBuying.taxType = ''
			this.dataBuying.taxType = ''
			this.getListTaxTypeBuyingDP(this.dataBuying.docCode)
			this.getListTrxDocTypeBuying(this.dataBuying.docCode)
		},
		changeDocCodeEdit() {
			if (this.dataSingleBuyingDownPayment.doc_code === 'DIGUNGGUNG' || this.dataSingleBuyingDownPayment.doc_code === 'PMSE' || this.dataSingleBuyingDownPayment.doc_code === 'SKP') {
				this.showTaxType = false;
			} else {
				this.showTaxType = true;
			}

			if(this.dataSingleBuyingDownPayment.doc_code === ''){
        this.showTaxType = false;
        this.showTaxDetail = false;
        this.showTaxInput = false;
        this.dataSingleBuying.tax_invoice_date = ''
        this.dataSingleBuying.tax_invoice_number = ''
        this.isTaxDateChecked = 0
      }

			this.dataSingleBuyingDownPayment.trx_doc_type = ''
			this.dataSingleBuyingDownPayment.tax_type = ''
			this.getListTaxTypeBuyingDP(this.dataSingleBuyingDownPayment.doc_code)
			this.getListTrxDocTypeBuying(this.dataSingleBuyingDownPayment.doc_code)
		},
		changeCurrency(value) {
			// this.dataBuyingDownPayment.currency = value
			this.dataBuyingDownPayment.commercialRate = 0
			this.dataBuyingDownPayment.fiscalRate = 0
			this.dataBuying.commercialRate = 0
			this.dataBuying.fiscalRate = 0

			if (this.statusModal === 'createDp') {
				this.getKurs(value)
					.then(() => {
						console.log(this.kursData, 'kursdata');
						if (this.kursData) {
							let commercialRate = parseInt(this.kursData.commercial_rate)
							let fiscalRate = parseInt(this.kursData.fiscal_rate)
							this.dataBuyingDownPayment.commercialRate = commercialRate
							this.dataBuyingDownPayment.fiscalRate = fiscalRate


						}
					})
				if (value !== this.dataDefaultCurrency.id) {
					this.showRate = true
				} else {
					this.showRate = false
				}
			} else if (this.statusModal === 'buyingCopy') {
				let buyingCurr, buyingDate;
				[buyingCurr, buyingDate] =
					[
						this.dataBuying.currency,
						this.dataBuying.date,
					]
				if (buyingCurr !== this.dataDefaultCurrency.id) {
					this.dataBuying.commercialRate = 0
					this.dataBuying.fiscalRate = 0
					this.sendKurs = {}

					this.sendKurs = {
						date: buyingDate,
						id: buyingCurr
					}

					this.getKursWithDate(this.sendKurs)
						.then(() => {
							console.log(this.kursData, 'kursdataDate')
							if (this.kursData) {
								let commercialRate = parseInt(this.kursData.commercial_rate)
								let fiscalRate = parseInt(this.kursData.fiscal_rate)
								this.dataBuying.commercialRate = commercialRate
								this.dataBuying.fiscalRate = fiscalRate
								this.showRate = true

							}
						})
				} else {
					this.showRate = false
				}
			}

			// if(value !== this.dataDefaultCurrency.id) {
			//   this.showRate = true
			// } else {
			//   this.showRate = false
			// }

			if (value !== 0) {
				this.isEnableTabTax = true
			} else {
				this.isEnableTabTax = false
			}

			// this.dataDefaultCurrency = this.dataBuyingDownPayment.currency
			// console.log(this.dataBuyingDownPayment.currency)
		},
		changeTerm(value) {
			console.log(this.dataBuyingDownPayment.term)
		},
		changeTermBuyingCopy(value) {
			console.log(this.dataBuyingDownPayment.term)
		},
		formatRupiah(angka, prefix) {
			console.log(angka, 'THIS ANGKAA')
			angka = angka.replace(/\./g, ',')
			let numberString = angka.replace(/[^,\d]/g, '').toString()
			// console.log(numberString, 'THIS ANGKAA 2')
			let split = numberString.split(',')
			let sisa = split[0].length % 3
			let rupiah = split[0].substr(0, sisa)
			let ribuan = split[0].substr(sisa).match(/\d{3}/gi)

			// tambahkan titik jika yang di input sudah menjadi angka ribuan
			if (ribuan) {
				let separator = sisa ? '.' : '';
				rupiah += separator + ribuan.join('.');
			}

			rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah;
			return prefix === undefined ? rupiah : (rupiah ? prefix + ' ' + rupiah : '');
		},
		validateBuyingDownPayment() {

			console.log(this.dataBuyingDownPayment, 'THIS DATA Buying Down Payment')

			// Ini pake Promise biar ngga asynchronus, karna klo ga pake promise jadinyaa error
			return new Promise((resolve, reject) => {
    const {
        number, numberText, downPayment, vendor, invoice, date, currency,
        commercialRate, fiscalRate, taxable, ppn, docCode, taxType
    } = this.dataBuyingDownPayment;

    const defaultCurrency = this.dataDefaultCurrency.id;

    if (number === "" && numberText === "") {
        Swal.fire({
            title: "Failed!",
            text: "Please insert field Number",
            icon: 'warning',
            confirmButtonColor: 'rgb(221, 107, 85)',
            confirmButtonText: 'Ok'
        });
        reject(console.log('required'))
		}

    if (downPayment === "" || downPayment === 0 || downPayment === "0") {
        Swal.fire({
            title: "Failed!",
            text: "Cash Advance must be more than 0",
            icon: 'warning',
            confirmButtonColor: 'rgb(221, 107, 85)',
            confirmButtonText: 'Ok'
        });
        reject(console.log('required'))
    }

    if (vendor === "") {
        Swal.fire({
            title: "Failed!",
            text: "Please insert field Vendor",
            icon: 'warning',
            confirmButtonColor: 'rgb(221, 107, 85)',
            confirmButtonText: 'Ok'
        });
        reject(console.log('required'))
		}

    if (invoice === "") {
        Swal.fire({
            title: "Failed!",
            text: "Please insert field Invoice No",
            icon: 'warning',
            confirmButtonColor: 'rgb(221, 107, 85)',
            confirmButtonText: 'Ok'
        });
        reject(console.log('required'))
    }

    if (date === "") {
        Swal.fire({
            title: "Failed!",
            text: "Please insert field Date",
            icon: 'warning',
            confirmButtonColor: 'rgb(221, 107, 85)',
            confirmButtonText: 'Ok'
        });
        reject(console.log('required'))
			}

    if (currency !== defaultCurrency) {
        if (commercialRate === "") {
            Swal.fire({
                title: "Failed!",
                text: "Please insert field Commercial Rate",
                icon: 'warning',
                confirmButtonColor: 'rgb(221, 107, 85)',
                confirmButtonText: 'Ok'
            });
            reject(console.log('required'))
        }
        if (fiscalRate === "") {
            Swal.fire({
                title: "Failed!",
                text: "Please insert field Fiscal Rate",
                icon: 'warning',
                confirmButtonColor: 'rgb(221, 107, 85)',
                confirmButtonText: 'Ok'
            });
            reject(console.log('required'))
        }
    }

    if (parseInt(taxable) === 1 && parseInt(ppn) > 0) {
        if (ppn === "") {
            Swal.fire({
                title: "Failed!",
                text: "Please insert field PPN",
                icon: 'warning',
                confirmButtonColor: 'rgb(221, 107, 85)',
                confirmButtonText: 'Ok'
            });
            reject(console.log('required'))
				}
        if (docCode === "") {
            Swal.fire({
                title: "Failed!",
                text: "Please insert field Document Code",
                icon: 'warning',
                confirmButtonColor: 'rgb(221, 107, 85)',
                confirmButtonText: 'Ok'
            });
            reject(console.log('required'))
        }
        if (taxType === "" && docCode !== "DIGUNGGUNG") {
            Swal.fire({
                title: "Failed!",
                text: "Please insert field Tax Type",
                icon: 'warning',
                confirmButtonColor: 'rgb(221, 107, 85)',
                confirmButtonText: 'Ok'
            });
            reject(console.log('required'))
        }
        if (docCode === 'CTAS_IMPORT' && this.dataBuyingDownPayment.trx_doc_type === "") {
            Swal.fire({
                title: "Failed!",
                text: "Please insert field Document Transaction",
                icon: 'warning',
                confirmButtonColor: 'rgb(221, 107, 85)',
                confirmButtonText: 'Ok'
            });
        }
    }

	if (this.dataBuyingDownPayment.isWINumber.toString() === "1" && this.companyIsUsingWorkingInstruction === 1) {
		if (this.dataBuyingDownPayment.wINumber === "" || this.dataBuyingDownPayment.wINumber === "0" || this.dataBuyingDownPayment.wINumber === 0) {
			Swal.fire({
				title: "Failed!",
				text: "Please insert field WI Number",
				icon: 'warning',
				// showCancelButton: true,
				confirmButtonColor: 'rgb(221, 107, 85)',
				// cancelButtonColor: '#C1C1C1',
				// reverseButtons: true,
				confirmButtonText: 'Ok'
			})

			reject(console.log('required'))
		}
	}

    resolve(true);
});

		},
		validatePurchasePayment() {
			// Ini pake Promise biar ngga asynchronus, karna klo ga pake promise jadinyaa error
			return new Promise((resolve, reject) => {
				if (this.dataBuyingPurchasePayment.number === "" && this.dataBuyingPurchasePayment.numberText === "") {
					console.log('OKEEEDDD')
					Swal.fire({
						title: "Failed!",
						text: "Please insert field Number",
						icon: 'warning',
						confirmButtonColor: 'rgb(221, 107, 85)',
						confirmButtonText: 'Ok'
					})

					reject(console.log('required'))
				}

				if (this.dataBuyingPurchasePayment.bank === "") {
					Swal.fire({
						title: "Failed!",
						text: "Please insert field Bank",
						icon: 'warning',
						confirmButtonColor: 'rgb(221, 107, 85)',
						confirmButtonText: 'Ok'
					})

					reject(console.log('required'))
				}

				if (this.dataBuyingPurchasePayment.paymentMethods === "") {
					Swal.fire({
						title: "Failed!",
						text: "Please insert field Payment Method",
						icon: 'warning',
						confirmButtonColor: 'rgb(221, 107, 85)',
						confirmButtonText: 'Ok'
					})

					reject(console.log('required'))
				}

				if (this.dataBuyingPurchasePayment.date === "") {
					Swal.fire({
						title: "Failed!",
						text: "Please insert field Date",
						icon: 'warning',
						confirmButtonColor: 'rgb(221, 107, 85)',
						confirmButtonText: 'Ok'
					})

					reject(console.log('required'))
				}

				resolve(true)
			})
		},
		validatePurchasePaymentTax() {
			// Ini pake Promise biar ngga asynchronus, karna klo ga pake promise jadinyaa error
			return new Promise((resolve, reject) => {
				if (this.dataBuyingPurchasePaymentTax.number === "" && this.dataBuyingPurchasePaymentTax.numberText === "") {
					console.log('OKEEEDDD')
					Swal.fire({
						title: "Failed!",
						text: "Please insert field Number",
						icon: 'warning',
						confirmButtonColor: 'rgb(221, 107, 85)',
						confirmButtonText: 'Ok'
					})

					reject(console.log('required'))
				}

				if (this.dataBuyingPurchasePaymentTax.bank === "") {
					Swal.fire({
						title: "Failed!",
						text: "Please insert field Bank",
						icon: 'warning',
						confirmButtonColor: 'rgb(221, 107, 85)',
						confirmButtonText: 'Ok'
					})

					reject(console.log('required'))
				}

				if (this.dataBuyingPurchasePaymentTax.paymentMethods === "") {
					Swal.fire({
						title: "Failed!",
						text: "Please insert field Payment Method",
						icon: 'warning',
						confirmButtonColor: 'rgb(221, 107, 85)',
						confirmButtonText: 'Ok'
					})

					reject(console.log('required'))
				}

				if (this.dataBuyingPurchasePaymentTax.date === "") {
					Swal.fire({
						title: "Failed!",
						text: "Please insert field Date",
						icon: 'warning',
						confirmButtonColor: 'rgb(221, 107, 85)',
						confirmButtonText: 'Ok'
					})

					reject(console.log('required'))
				}

				resolve(true)
			})
		},
		closeModalDownPayment() {
			this.$nextTick(() => {
				this.$bvModal.hide('modalBuyingDownPayment')
			})
		},
		onComplete() {
			// let idJoborder = window.location.href.split("/").splice(0, 6)[5];

			this.dataBuyingPurchasePayment.currency = this.dataBuyingDownPayment.currency
			this.dataBuyingPurchasePayment.commercialRate = this.dataBuyingDownPayment.commercialRate
			this.dataBuyingPurchasePayment.fiscalRate = this.dataBuyingDownPayment.fiscalRate
			this.dataBuyingPurchasePayment.amount = this.dataBuyingDownPayment.downPayment
			this.dataBuyingPurchasePayment.vendor = this.dataBuyingDownPayment.vendor

			this.dataBuyingPurchasePaymentTax.currency = this.dataBuyingDownPayment.currency
			this.dataBuyingPurchasePaymentTax.commercialRate = this.dataBuyingDownPayment.commercialRate
			this.dataBuyingPurchasePaymentTax.fiscalRate = this.dataBuyingDownPayment.fiscalRate
			this.dataBuyingPurchasePaymentTax.amount = this.dataBuyingDownPayment.downPayment
			this.dataBuyingPurchasePaymentTax.vendor = this.dataBuyingDownPayment.vendor

			// this.dataBuyingPurchasePaymentTax.currencyBank = this.dataBuyingPurchasePayment.currencyBank


			if (parseFloat(this.dataBuyingDownPayment.ppn) > 0) {
				if (!this.dataBuyingDownPayment.inclusiveTax || this.dataBuyingDownPayment.inclusiveTax === "0") {
					let amountPPN = this.dataBuyingDownPayment.downPayment * this.dataBuyingDownPayment.ppn / 100
					if (this.dataBuyingDownPayment.currency === 0) {

						this.dataBuyingPurchasePayment.amount = parseInt(this.dataBuyingDownPayment.downPayment) + amountPPN
					} else {
						this.dataBuyingPurchasePaymentTax.currency = 0
						this.dataBuyingPurchasePaymentTax.amount = amountPPN
					}
				}
			}

			if (parseInt(this.dataBuyingDownPayment.taxable) === 0) {
				this.dataBuyingDownPayment.ppn = 0
				this.dataBuyingDownPayment.inclusiveTax = 'false'
			}

			if (this.dataBuyingDownPayment.isWINumber.toString() !== "1") {
				this.dataBuyingDownPayment.wINumber = ''
			}

			let discountPP = []
			let discountPPTax = []

			this.itemDiscountPP.forEach((dt, idx) => {
				if (dt.discountAccount !== "" && dt.discountAccount !== 0 && dt.discountAccount !== null && dt.discountAccount !== undefined) {
					discountPP.push(dt)
				}
			})

			this.itemDiscountPPTax.forEach((dt, idx) => {
				if (dt.discountAccount !== "" && dt.discountAccount !== 0 && dt.discountAccount !== null && dt.discountAccount !== undefined) {
					discountPPTax.push(dt)
				}
			})

			// let idJoborder = window.location.href.split("/").splice(0, 6)[5];

			let mergeData = {
				// id_joborder: idJoborder,
				id_default: this.dataDefaultCurrency.id,
				buying: this.dataBuyingDownPayment,
				itemBuying: this.itemBuying,
				purchasePayment: this.dataBuyingPurchasePayment,
				purchasePaymentTax: this.dataBuyingPurchasePaymentTax,
				itemDiscountPP: discountPP,
				itemDiscountPPTax: discountPPTax,
			}

			console.log(mergeData, 'THIS MERGE DATA');

			Swal.fire({
					title: 'Now loading',
					allowEscapeKey: false,
					allowOutsideClick: false,
					didOpen: () => {
							Swal.showLoading();
					}
			}).then();


			this.isLoadingForm = true

			let headers = {
				'Content-Type': 'application/json',

			}

			this.saveBuyingDownPayment({
				payload: mergeData,
				headers: headers
			})
				.then((res) => {
					this.isLoadingForm = false
					if (res.data.status) {
						const Toast = Swal.mixin({
							toast: true,
							position: 'bottom-end',
							showConfirmButton: false,
							timer: 2000,
							timerProgressBar: true,
							onOpen: (toast) => {
								toast.addEventListener('mouseenter', Swal.stopTimer)
								toast.addEventListener('mouseleave', Swal.resumeTimer)
							}
						})

						Toast.fire({
							icon: 'success',
							title: 'successfully'
						})

						this.$nextTick(() => {
							this.$bvModal.hide('modalBuyingDownPayment')
						})

						this.$nextTick(() => this.$refs.vuetable.refresh())
					} else {
						Swal.fire({
							title: "Failed!",
							text: res.data.message,
							icon: 'warning',
							confirmButtonColor: 'rgb(221, 107, 85)',
							confirmButtonText: 'Ok'
						})

						this.$nextTick(() => {
							this.$bvModal.hide('modalBuyingDownPayment')
						})

						this.$nextTick(() => this.$refs.vuetable.refresh())
					}
				})
				.catch((err) => {
					this.isLoadingForm = false
					Swal.fire({
						title: "Failed!",
						text: err.response.data.message,
						icon: 'warning',
						confirmButtonColor: 'rgb(221, 107, 85)',
						confirmButtonText: 'Ok'
					})
				})
			// console.log(mergeData, 'THIS COLLECTION DATA')
		},

		// Kondisi kolom autonumber
		numberConditionColumn() {
			let dp, pp, ppTax;
			[dp, pp, ppTax] =
				[this.dataSingleBuyingDownPayment,
				this.dataSingleBuyingPurchasePayment,
				this.dataSingleBuyingPurchasePaymentTax
				]

			// Logging
			// console.log(dp,'dp');
			// console.log(pp,'pp');
			// console.log(ppTax,'pp');

			// DP condition
			if (dp.number || dp.number !== '') {
				this.isManualNumberBuyingDownPaymentEdit = true
				this.dataSingleBuyingDownPayment.numberText = dp.number
				this.dataSingleBuyingDownPayment.number = dp.id_autonumber
			} else {
				this.isManualNumberBuyingDownPaymentEdit = false
				this.dataSingleBuyingDownPayment.number = dp.id_autonumber
			}

			// PP condition
			if (pp.number || pp.number !== '') {
				this.isManualNumberBuyingPurchasePaymentEdit = true
				this.dataSingleBuyingPurchasePayment.numberText = pp.number
				this.dataSingleBuyingPurchasePayment.number = pp.id_autonumber
			} else {
				this.isManualNumberBuyingPurchasePaymentEdit = false
				this.dataSingleBuyingPurchasePayment.number = pp.id_autonumber
			}

			// PP Tax condition
			if (this.editTaxAble === true) {
				if (ppTax.number || ppTax.number !== '') {
					this.isManualNumberBuyingPurchasePaymentTaxEdit = true
					this.dataSingleBuyingPurchasePaymentTax.numberText = ppTax.number
					this.dataSingleBuyingPurchasePaymentTax.number = ppTax.id_autonumber
				} else {
					this.isManualNumberBuyingPurchasePaymentTaxEdit = false
					this.dataSingleBuyingPurchasePaymentTax.number = ppTax.id_autonumber
				}
			}
		},
		editInfoBuying(id) {

			console.log(id, 'IDNYA')
			this.itemDiscountPPTax = [
				{
					discountAccount: '',
					discountType: '',
					discount: '',
				}
			]
			try {
				this.getListAutonumberPPTax(35)
				this.getListAutonumber(31)
				this.getListAutonumberPP(32)

				this.getBuyingDownPayment(id)
					.then((res) => {



						let costYear = moment(res.cost_date).year()
						let costTaxYear = res.date !== null ? moment(res.date).year() : null
						console.log(costTaxYear,'date nya')
						console.log(costYear,'date nya')

						if(costTaxYear !== null){
							if(costTaxYear === 2024){
								this.getListDocCodeNew(1)
							}else{
								this.getListDocCodeNew(2)
							}
						}else{
							if(costYear === 2024){
								this.getListDocCodeNew(1)
							}else{
								this.getListDocCodeNew(2)
							}
						}
						if (this.dataSingleBuyingDownPayment.doc_code === 'DIGUNGGUNG' || this.dataSingleBuyingDownPayment.doc_code === 'PMSE' || this.dataSingleBuyingDownPayment.doc_code === 'SKP') {
							this.showTaxType = false;
						} else {
							this.showTaxType = true;
						}

						if(this.dataSingleBuyingDownPayment.id_wi_number !== null && this.dataSingleBuyingDownPayment.id_wi_number !== '' && this.dataSingleBuyingDownPayment.id_wi_number !== 0) {
							this.dataSingleBuyingDownPayment.isWINumber = "1"
							this.getListWINumber(this.dataSingleBuyingDownPayment.id_wi_number)
						} else {
							this.dataSingleBuyingDownPayment.isWINumber = "0"
							this.getListWINumber()
						}

						// if(this.dataSingleBuyingDownPayment.doc_code === ''){
						// 	this.showTaxType = false;
						// 	this.showTaxDetail = false;
						// 	this.showTaxInput = false;
						// 	this.dataSingleBuying.tax_invoice_date = ''
						// 	this.dataSingleBuying.tax_invoice_number = ''
						// 	this.isTaxDateChecked = 0
						// }

						// this.dataSingleBuyingDownPayment.trx_doc_type = ''
						// this.dataSingleBuyingDownPayment.tax_type = ''
						// this.getListTaxTypeBuyingDP(this.dataSingleBuyingDownPayment.doc_code)
						this.getListTrxDocTypeBuying(this.dataSingleBuyingDownPayment.doc_code)


						this.getListDocCodeDP()
							.then((res) => {
								this.getBuyingPurchasePayment(id)
									.then((res) => {


										if (res.discounts !== undefined && res.discounts !== null) {
											if (res.discounts.length > 0) {
												this.itemDiscountPP = [];
											} else {
												this.itemDiscountPP = [
													{
														discountAccount: '',
														discountType: '',
														discount: '',
													}
												]
											}
											res.discounts.forEach((dr) => {
												this.itemDiscountPP.push({
													discountAccount: dr.discount_account,
													discountType: dr.discount_type,
													discount: dr.discount,
													id_aol: dr.id_aol,
													project: dr.id_project,
													departemen: dr.id_departemen
												})
											})
										}
										if (this.dataSingleBuyingDownPayment.id_currency !== 0 && this.dataSingleBuyingDownPayment.ppn > 0) {
											this.editTaxAble = true
											this.getBuyingPurchasePaymentTax(id)
												.then((resTax) => {
													if (resTax.discounts !== undefined && resTax.discounts !== null) {
														if (resTax.discounts.length > 0) {
															this.itemDiscountPPTax = [];
														} else {
															this.itemDiscountPPTax = [
																{
																	discountAccount: '',
																	discountType: '',
																	discount: '',
																}
															]
														}
														resTax.discounts.forEach((dr) => {
															this.itemDiscountPPTax.push({
																discountAccount: dr.discount_account,
																discountType: dr.discount_type,
																discount: dr.discount,
																id_aol: dr.id_aol,
																project: dr.id_project,
																departemen: dr.id_departemen
															})
														})
													}
													this.numberConditionColumn()
													this.dataSingleBuyingPurchasePaymentTax.id_currency_bank = 0
													if (this.dataSingleBuyingPurchasePaymentTax.id === undefined) {
														this.editTaxAble = false
														this.getBuyingPurchasePaymentTaxDefault(id)
															.then((res) => {
																this.dataSingleBuyingDownPayment.date = new Date(this.dataSingleBuyingDownPayment.date)
																this.getListTerm(this.dataSingleBuyingDownPayment.id_vendor)
																	.then((res) => {
																		this.getListTaxTypeBuyingDP(this.dataSingleBuyingDownPayment.doc_code)
																			.then((res) => {
																				// console.log(this.dataSingleBuyingDownPayment.date.replace(/-/g, '/'))
																			})
																		this.$bvModal.show('modalBuyingDownPaymentEdit')
																	})
															})
															.catch((err) => {
																console.log(err)
															})
													} else {
														this.dataSingleBuyingDownPayment.date = new Date(this.dataSingleBuyingDownPayment.date)
														this.getListTerm(this.dataSingleBuyingDownPayment.id_vendor)
															.then((res) => {
																this.getListTaxTypeBuyingDP(this.dataSingleBuyingDownPayment.doc_code)
																	.then((res) => {
																		// console.log(this.dataSingleBuyingDownPayment.date.replace(/-/g, '/'))
																	})
																this.$bvModal.show('modalBuyingDownPaymentEdit')
															})
													}
												})
												.catch((err) => {
													console.log(err)
												})
										} else {
											this.editTaxAble = false
											this.getBuyingPurchasePaymentTaxDefault(id)
												.then((res) => {
													this.dataSingleBuyingDownPayment.date = new Date(this.dataSingleBuyingDownPayment.date)
													this.numberConditionColumn()
													this.getListTerm(this.dataSingleBuyingDownPayment.id_vendor)
														.then((res) => {
															this.getListTaxTypeBuyingDP(this.dataSingleBuyingDownPayment.doc_code)
																.then((res) => {
																	// console.log(this.dataSingleBuyingDownPayment.date.replace(/-/g, '/'))
																})
															this.$bvModal.show('modalBuyingDownPaymentEdit')
														})
												})
												.catch((err) => {
													console.log(err)
												})
										}
									})
									.catch((err) => {
										console.log(err)
									})
							})
							.catch((err) => {
								console.log(err)
							})
					})
					.catch((err) => {
						console.log(err)
					})
			} catch (error) {
				console.log(error)
			}
		},
		submitInfoBuying(bvModalEvent) {
			// this.$bvModal.hide('modalBuyingDownPaymentEdit')
			bvModalEvent.preventDefault()

			// this.$nextTick(() => {
			//   this.$bvModal.hide('modalBuyingDownPaymentEdit')
			// })

			if (this.dataSingleBuyingDownPayment.invoice_no === "") {
				Swal.fire({
					title: "Failed!",
					text: "Please insert field Invoice No",
					icon: 'warning',
					// showCancelButton: true,
					confirmButtonColor: 'rgb(221, 107, 85)',
					// cancelButtonColor: '#C1C1C1',
					// reverseButtons: true,
					confirmButtonText: 'Ok'
				})

				return false;
			}

			if (this.dataSingleBuyingDownPayment.down_payment === "" || this.dataSingleBuyingDownPayment.down_payment === "0" || this.dataSingleBuyingDownPayment.down_payment === 0) {
				Swal.fire({
					title: "Failed!",
					text: "Cash Advance must be more than 0",
					icon: 'warning',
					// showCancelButton: true,
					confirmButtonColor: 'rgb(221, 107, 85)',
					// cancelButtonColor: '#C1C1C1',
					// reverseButtons: true,
					confirmButtonText: 'Ok'
				})

				return false;
			}

			if (this.dataSingleBuyingDownPayment.date === "") {
				Swal.fire({
					title: "Failed!",
					text: "Please insert field Date",
					icon: 'warning',
					// showCancelButton: true,
					confirmButtonColor: 'rgb(221, 107, 85)',
					// cancelButtonColor: '#C1C1C1',
					// reverseButtons: true,
					confirmButtonText: 'Ok'
				})

				return false;
			}

			if (this.dataSingleBuyingDownPayment.isWINumber.toString() === "1" && this.companyIsUsingWorkingInstruction === 1) {
				if (this.dataSingleBuyingDownPayment.id_wi_number === "" || this.dataSingleBuyingDownPayment.id_wi_number === "0" || this.dataSingleBuyingDownPayment.id_wi_number === 0) {
					Swal.fire({
						title: "Failed!",
						text: "Please insert field WI Number",
						icon: 'warning',
						// showCancelButton: true,
						confirmButtonColor: 'rgb(221, 107, 85)',
						// cancelButtonColor: '#C1C1C1',
						// reverseButtons: true,
						confirmButtonText: 'Ok'
					})

					return false
				}
			} else {
				this.dataSingleBuyingDownPayment.id_wi_number = ''
			}

			if (parseInt(this.dataSingleBuyingDownPayment.taxable) === 1 && parseInt(this.dataSingleBuyingDownPayment.ppn) > 0) {
				if (this.dataSingleBuyingDownPayment.ppn === "") {
						Swal.fire({
								title: "Failed!",
								text: "Please insert field PPN",
								icon: 'warning',
								confirmButtonColor: 'rgb(221, 107, 85)',
								confirmButtonText: 'Ok'
						});
						return false;
				}
				if (this.dataSingleBuyingDownPayment.doc_code === "") {
						Swal.fire({
								title: "Failed!",
								text: "Please insert field Document Code",
								icon: 'warning',
								confirmButtonColor: 'rgb(221, 107, 85)',
								confirmButtonText: 'Ok'
						});
						return false;
				}
				if (this.dataSingleBuyingDownPayment.tax_type === "" && this.dataSingleBuyingDownPayment.doc_code !== "DIGUNGGUNG") {
						Swal.fire({
								title: "Failed!",
								text: "Please insert field Tax Type",
								icon: 'warning',
								confirmButtonColor: 'rgb(221, 107, 85)',
								confirmButtonText: 'Ok'
						});
						return false;
				}
				if (this.dataSingleBuyingDownPayment.doc_code === 'CTAS_IMPORT' && this.dataSingleBuyingDownPayment.trx_doc_type === "") {
						Swal.fire({
								title: "Failed!",
								text: "Please insert field Document Transaction",
								icon: 'warning',
								confirmButtonColor: 'rgb(221, 107, 85)',
								confirmButtonText: 'Ok'
						});
						return false;
				}
			}


			this.dataSingleBuyingPurchasePayment.currency = this.dataSingleBuyingDownPayment.id_currency
			this.dataSingleBuyingPurchasePayment.commercialRate = this.dataSingleBuyingDownPayment.price_kurs
			this.dataSingleBuyingPurchasePayment.fiscalRate = this.dataSingleBuyingDownPayment.fiscal_kurs
			this.dataSingleBuyingPurchasePayment.amount = this.dataSingleBuyingDownPayment.down_payment
			this.dataSingleBuyingPurchasePayment.vendor = this.dataSingleBuyingDownPayment.id_vendor

			if (!this.editTaxAble) {
				let copyNumber = this.dataSingleBuyingPurchasePaymentTax.number
				this.dataSingleBuyingPurchasePaymentTax.currency = this.dataSingleBuyingDownPayment.id_currency
				this.dataSingleBuyingPurchasePaymentTax.commercialRate = this.dataSingleBuyingDownPayment.price_kurs
				this.dataSingleBuyingPurchasePaymentTax.fiscalRate = this.dataSingleBuyingDownPayment.fiscal_kurs
				// this.dataSingleBuyingPurchasePaymentTax.amount = this.dataSingleBuyingDownPayment.down_payment
				this.dataSingleBuyingPurchasePaymentTax.vendor = this.dataSingleBuyingDownPayment.id_vendor
				this.dataSingleBuyingPurchasePaymentTax.currencyBank = this.dataSingleBuyingPurchasePaymentTax.id_currency_bank
				// this.dataSingleBuyingPurchasePaymentTax.currencyBank = 0
				this.dataSingleBuyingPurchasePaymentTax.number = this.dataSingleBuyingPurchasePaymentTax.id_autonumber
				this.dataSingleBuyingPurchasePaymentTax.numberText = copyNumber
				this.dataSingleBuyingPurchasePaymentTax.paymentMethod = this.dataSingleBuyingPurchasePaymentTax.payment_method
				this.dataSingleBuyingPurchasePaymentTax.discountAccount = this.dataSingleBuyingPurchasePaymentTax.discount_account
				this.dataSingleBuyingPurchasePaymentTax.discountType = this.dataSingleBuyingPurchasePaymentTax.discount_type

				if (this.dataSingleBuyingDownPayment.ppn > 0 && (this.dataSingleBuyingDownPayment.id_currency !== 0 && this.dataSingleBuyingDownPayment.id_currency !== '0')) {
					// console.log(this.dataSingleBuyingPurchasePaymentTax, 'OKEEEDDD')
					// return false
					if (this.dataSingleBuyingPurchasePaymentTax.number === "" && this.dataSingleBuyingPurchasePaymentTax.numberText === "") {
						Swal.fire({
							title: "Failed!",
							text: "Please insert field Number",
							icon: 'warning',
							confirmButtonColor: 'rgb(221, 107, 85)',
							confirmButtonText: 'Ok'
						})

						return false
					}

					if (this.dataSingleBuyingPurchasePaymentTax.bank === "") {
						Swal.fire({
							title: "Failed!",
							text: "Please insert field Bank",
							icon: 'warning',
							confirmButtonColor: 'rgb(221, 107, 85)',
							confirmButtonText: 'Ok'
						})

						return false
					}

					if (this.dataSingleBuyingPurchasePaymentTax.paymentMethod === "") {
						Swal.fire({
							title: "Failed!",
							text: "Please insert field Payment Method",
							icon: 'warning',
							confirmButtonColor: 'rgb(221, 107, 85)',
							confirmButtonText: 'Ok'
						})

						return false
					}

					if (this.dataSingleBuyingPurchasePaymentTax.date === "" || this.dataSingleBuyingPurchasePaymentTax.date == null || this.dataSingleBuyingPurchasePaymentTax.date === "0") {
						Swal.fire({
							title: "Failed!",
							text: "Please insert field Date",
							icon: 'warning',
							confirmButtonColor: 'rgb(221, 107, 85)',
							confirmButtonText: 'Ok'
						})

						return false
					}
				}
			}

			this.dataSingleBuyingPurchasePaymentTax.id_currency = this.dataSingleBuyingDownPayment.id_currency
			this.dataSingleBuyingPurchasePaymentTax.commercial_rate = this.dataSingleBuyingDownPayment.price_kurs
			this.dataSingleBuyingPurchasePaymentTax.fiscal_rate = this.dataSingleBuyingDownPayment.fiscal_kurs
			// this.dataSingleBuyingPurchasePaymentTax.amount = this.dataSingleBuyingDownPayment.down_payment
			this.dataSingleBuyingPurchasePaymentTax.id_vendor = this.dataSingleBuyingDownPayment.id_vendor

			if (parseInt(this.dataSingleBuyingDownPayment.taxable) === 0) {
				this.dataSingleBuyingDownPayment.ppn = 0
				this.dataSingleBuyingDownPayment.inclusiveTax = '0'
			}

			if (parseFloat(this.dataSingleBuyingDownPayment.ppn) > 0) {
				if (this.dataSingleBuyingDownPayment.inclusive_tax === "false" || this.dataSingleBuyingDownPayment.inclusive_tax === "0") {
					let amountPPN = this.dataSingleBuyingDownPayment.down_payment * this.dataSingleBuyingDownPayment.ppn / 100
					if (this.dataSingleBuyingDownPayment.id_currency === 0) {
						// this.dataBuyingPurchasePayment.amount = parseInt(this.dataBuyingDownPayment.downPayment) + amountPPN
						this.dataSingleBuyingPurchasePayment.amount = parseInt(this.dataSingleBuyingDownPayment.down_payment) + amountPPN
					} else {
						this.dataSingleBuyingPurchasePaymentTax.id_currency = 0
						this.dataSingleBuyingPurchasePaymentTax.amount = amountPPN
					}
				}
			}

			let discountPP = []
			let discountPPTax = []

			this.itemDiscountPP.forEach((dt, idx) => {
				if (dt.discountAccount !== "" && dt.discountAccount !== 0 && dt.discountAccount !== null && dt.discountAccount !== undefined) {
					discountPP.push(dt)
				}
			})

			this.itemDiscountPPTax.forEach((dt, idx) => {
				if (dt.discountAccount !== "" && dt.discountAccount !== 0 && dt.discountAccount !== null && dt.discountAccount !== undefined) {
					discountPPTax.push(dt)
				}
			})


			Swal.fire({
					title: 'Now loading',
					allowEscapeKey: false,
					allowOutsideClick: false,
					didOpen: () => {
							Swal.showLoading();
					}
			}).then();



			this.isLoadingForm = true

			let headers = {
				'Content-Type': 'application/json'
			}

			this.updateBuyingDownPayment({
				payload: {
					downPayment: this.dataSingleBuyingDownPayment,
					purchasePayment: this.dataSingleBuyingPurchasePayment,
					purchasePaymentTax: this.dataSingleBuyingPurchasePaymentTax,
					itemDiscountPP: discountPP,
					itemDiscountPPTax: discountPPTax,
				},
				headers: headers
			})
				.then((res) => {

					this.isLoadingForm = false
					if (res.data.status) {
						const Toast = Swal.mixin({
							toast: true,
							position: 'bottom-end',
							showConfirmButton: false,
							timer: 2000,
							timerProgressBar: true,
							onOpen: (toast) => {
								toast.addEventListener('mouseenter', Swal.stopTimer)
								toast.addEventListener('mouseleave', Swal.resumeTimer)
							}
						})

						Toast.fire({
							icon: 'success',
							title: 'successfully'
						})

						this.$nextTick(() => {
							this.$bvModal.hide('modalBuyingDownPaymentEdit')
						})

						// let idJoborder = window.location.href.split("/").splice(0, 6)[5];
						// this.getListBuyingDownPayment(idJoborder)
						this.$nextTick(() => this.$refs.vuetable.refresh())
					} else {
						Swal.fire({
							title: "Failed!",
							text: res.data.message,
							icon: 'warning',
							confirmButtonColor: 'rgb(221, 107, 85)',
							confirmButtonText: 'Ok'
						})
					}
				})
				.catch((err) => {
					this.isLoadingForm = false
					console.log(err.response.data)
					Swal.fire({
						title: "Failed!",
						text: err.response.data.message,
						icon: 'warning',
						confirmButtonColor: 'rgb(221, 107, 85)',
						confirmButtonText: 'Ok'
					})
				})


		},
		deleteBuyingData(id, countPi) {
			let Permission = JSON.parse(localStorage.getItem('userPermissions'));

			if (countPi > 0) {
				Swal.fire({
					title: "Failed!",
					text: "Cannot delete Cash Advance because has been used in Vendor Invoice / Settlement, you must first delete Vendor Invoice / Settlement if you want to delete Cash Advance",
					icon: 'warning',
					confirmButtonColor: 'rgb(221, 107, 85)',
					confirmButtonText: 'Ok'
				})
			} else {
				this.getBuyingDownPayment(id)
					.then((res) => {
						// this.dataSingleBuyingDownPayment.is_copied = new Date(this.dataSingleBuyingDownPayment.date)
						if (this.dataSingleBuyingDownPayment.is_copied === 0) {
							Swal.fire({
								title: 'Are you sure?',
								text: 'You will not be able to recover this data! ',
								icon: 'warning',
								showCancelButton: true,
								confirmButtonColor: 'rgb(221, 107, 85)',
								cancelButtonColor: '#C1C1C1',
								reverseButtons: true,
								confirmButtonText: 'Yes, delete it!',
							}).then((result) => {
								if (result.isConfirmed) {
									let headers = {
										'Content-Type': 'application/json'
									}

									this.deleteBuyingDownPayment({
										payload: id,
										headers: headers
									})
										.then((res) => {
											if (res.data.status) {
												const Toast = Swal.mixin({
													toast: true,
													position: 'bottom-end',
													showConfirmButton: false,
													timer: 2000,
													timerProgressBar: true,
													onOpen: (toast) => {
														toast.addEventListener('mouseenter', Swal.stopTimer)
														toast.addEventListener('mouseleave', Swal.resumeTimer)
													}
												})

												Toast.fire({
													icon: 'success',
													title: 'successfully'
												})

												// let idJoborder = window.location.href.split("/").splice(0, 6)[5];
												// this.getListBuyingDownPayment(idJoborder)
												this.$nextTick(() => this.$refs.vuetable.refresh())
											} else {

												if (Permission.includes('CASH_ADVANCE_HARD_DELETE')) {
													if (res.data.message === 'Delete data aol failed - Pembayaran Pembelian tidak ditemukan atau sudah dihapus') {
														Swal.fire({
															title: 'Are you sure hard delete Data?',
															text: 'You will not be able to recover this data!',
															icon: 'warning',
															showCancelButton: true,
															confirmButtonColor: 'rgb(221, 107, 85)',
															cancelButtonColor: '#C1C1C1',
															reverseButtons: true,
															confirmButtonText: 'Yes, delete it!',
														}).then((result) => {
															if (result.isConfirmed) {
																this.hardDeleteBuyingDownPayment({
																	payload: id,
																	headers: headers
																})
																	.then((res) => {
																		Swal.fire({
																			title: "Success!",
																			text: "hard Delete Data",
																			icon: 'success',
																			confirmButtonColor: '#51C1AD',
																			confirmButtonText: 'Ok'
																		}).then((res) => {
																			this.$nextTick(() => {
																				this.$nextTick(() => this.$refs.vuetable.refresh())
																				// location.reload();
																			})
																		})
																	})
															}
														})
													} else {
														Swal.fire({
															// title: "Failed!",
															text: res.data.message,
															icon: 'warning',
															confirmButtonColor: 'rgb(221, 107, 85)',
															confirmButtonText: 'Ok'
														})
													}

												} else {
													Swal.fire({
														// title: "Failed!",
														text: res.data.message,
														icon: 'warning',
														confirmButtonColor: 'rgb(221, 107, 85)',
														confirmButtonText: 'Ok'
													})
												}
											}
										})
										.catch((err) => {
											console.log(err.response.data)
											Swal.fire({
												title: "Failed!",
												text: err.response.data.message,
												icon: 'warning',
												confirmButtonColor: 'rgb(221, 107, 85)',
												confirmButtonText: 'Ok'
											})
										})
								}
							})
						} else {
							Swal.fire({
								title: 'Warning',
								text: 'You cannot delete the Cash Advance if the Buying transaction has not been deleted!',
								icon: 'warning',
								type: "warning",
								showCancelButton: true,
								showDenyButton: true,
								confirmButtonColor: 'rgb(221, 107, 85)',
								cancelButtonColor: '#C1C1C1',
								reverseButtons: true,
								closeOnConfirm: false,
								confirmButtonText: 'Cash Advance Only!',
								denyButtonText: 'Cash Advance and Buying!',
								customClass: {
									denyButton: 'button-gamb',
									cancelButton: 'button-gamb',
									confirmButton: 'button-gamb'
								}
							}).then((result) => {
								if (result.isConfirmed) {
									let headers = {
										'Content-Type': 'application/json'
									}

									this.deleteBuyingDownPayment({
										payload: id,
										headers: headers
									})
										.then((res) => {
											console.log(res.data, 'datanya')
											if (res.data.status) {
												const Toast = Swal.mixin({
													toast: true,
													position: 'bottom-end',
													showConfirmButton: false,
													timer: 2000,
													timerProgressBar: true,
													onOpen: (toast) => {
														toast.addEventListener('mouseenter', Swal.stopTimer)
														toast.addEventListener('mouseleave', Swal.resumeTimer)
													}
												})

												Toast.fire({
													icon: 'success',
													title: 'successfully'
												})

												// let idJoborder = window.location.href.split("/").splice(0, 6)[5];
												// this.getListBuyingDownPayment(idJoborder)
												// this.getListBuying(idJoborder)
												this.$nextTick(() => this.$refs.vuetable.refresh())
											} else {
												Swal.fire({
													title: "Failed!",
													text: res.data.message,
													icon: 'warning',
													confirmButtonColor: 'rgb(221, 107, 85)',
													confirmButtonText: 'Ok'
												})
											}
										})
										.catch((err) => {
											console.log(err.response.data)
											Swal.fire({
												title: "Failed!",
												text: err.response.data.message,
												icon: 'warning',
												confirmButtonColor: 'rgb(221, 107, 85)',
												confirmButtonText: 'Ok'
											})
										})
								} else if (result.isDenied) {
									let headers = {
										'Content-Type': 'application/json'
									}

									this.deleteBuyingDownPaymentWithBuying({
										payload: id,
										headers: headers
									})
										.then((res) => {
											console.log(res.data, 'datanya')
											if (res.data.status) {
												const Toast = Swal.mixin({
													toast: true,
													position: 'bottom-end',
													showConfirmButton: false,
													timer: 2000,
													timerProgressBar: true,
													onOpen: (toast) => {
														toast.addEventListener('mouseenter', Swal.stopTimer)
														toast.addEventListener('mouseleave', Swal.resumeTimer)
													}
												})

												Toast.fire({
													icon: 'success',
													title: 'successfully'
												})

												// let idJoborder = window.location.href.split("/").splice(0, 6)[5];
												// this.getListBuyingDownPayment(idJoborder)
												// this.getListBuying(idJoborder)
												this.$nextTick(() => this.$refs.vuetable.refresh())
											} else {
												Swal.fire({
													title: "Failed!",
													text: res.data.message,
													icon: 'warning',
													confirmButtonColor: 'rgb(221, 107, 85)',
													confirmButtonText: 'Ok'
												})
											}
										})
										.catch((err) => {
											console.log(err.response.data)
											Swal.fire({
												title: "Failed!",
												text: err.response.data.message,
												icon: 'warning',
												confirmButtonColor: 'rgb(221, 107, 85)',
												confirmButtonText: 'Ok'
											})
										})
								}
							})
						}
					})
					.catch((err) => {
						console.log(err)
					})
			}
		},
		submitToAol(id) {
			let headers = {
				'Content-Type': 'application/json'
			}

			this.submitAOL({
				payload: id,
				headers: headers
			})
				.then((res) => {
					console.log(res.data, 'datanya')
					if (res.data === 1) {
						const Toast = Swal.mixin({
							toast: true,
							position: 'bottom-end',
							showConfirmButton: false,
							timer: 2000,
							timerProgressBar: true,
							onOpen: (toast) => {
								toast.addEventListener('mouseenter', Swal.stopTimer)
								toast.addEventListener('mouseleave', Swal.resumeTimer)
							}
						})

						Toast.fire({
							icon: 'success',
							title: 'successfully'
						})

						// let idJoborder = window.location.href.split("/").splice(0, 6)[5];
						// this.getListBuyingDownPayment(idJoborder)
						this.$nextTick(() => this.$refs.vuetable.refresh())
					} else {
						Swal.fire({
							title: "Failed!",
							text: res.data,
							icon: 'warning',
							confirmButtonColor: 'rgb(221, 107, 85)',
							confirmButtonText: 'Ok'
						})
					}
				})
				.catch((err) => {
					console.log(err.response.data)
					Swal.fire({
						title: "Failed!",
						text: err.response.data.message,
						icon: 'warning',
						confirmButtonColor: 'rgb(221, 107, 85)',
						confirmButtonText: 'Ok'
					})
				})
		},
		submitToAolPP(id, idDP) {
			console.log(id, 'THIS ID PP')
			console.log(idDP, 'THIS ID DP')
			let headers = {
				'Content-Type': 'application/json'
			}

			this.submitAOLPP({
				payload: {
					id: id,
					idDP: idDP
				},
				headers: headers
			})
				.then((res) => {
					console.log(res.data, 'datanya')
					if (res.data === 1) {
						const Toast = Swal.mixin({
							toast: true,
							position: 'bottom-end',
							showConfirmButton: false,
							timer: 2000,
							timerProgressBar: true,
							onOpen: (toast) => {
								toast.addEventListener('mouseenter', Swal.stopTimer)
								toast.addEventListener('mouseleave', Swal.resumeTimer)
							}
						})

						Toast.fire({
							icon: 'success',
							title: 'successfully'
						})

						// let idJoborder = window.location.href.split("/").splice(0, 6)[5];
						// this.getListBuyingDownPayment(idJoborder)
						this.$nextTick(() => this.$refs.vuetable.refresh())
					} else {
						Swal.fire({
							title: "Failed!",
							text: res.data,
							icon: 'warning',
							confirmButtonColor: 'rgb(221, 107, 85)',
							confirmButtonText: 'Ok'
						})
					}
				})
				.catch((err) => {
					console.log(err.response.data)
					Swal.fire({
						title: "Failed!",
						text: err.response.data.message,
						icon: 'warning',
						confirmButtonColor: 'rgb(221, 107, 85)',
						confirmButtonText: 'Ok'
					})
				})
		},
		submitToAolPPTax(id, idDP) {
			console.log(id, 'THIS ID PP')
			console.log(idDP, 'THIS ID DP')
			let headers = {
				'Content-Type': 'application/json'
			}

			this.submitAOLPPTax({
				payload: {
					id: id,
					idDP: idDP
				},
				headers: headers
			})
				.then((res) => {
					console.log(res.data, 'datanya')
					if (res.data === 1) {
						const Toast = Swal.mixin({
							toast: true,
							position: 'bottom-end',
							showConfirmButton: false,
							timer: 2000,
							timerProgressBar: true,
							onOpen: (toast) => {
								toast.addEventListener('mouseenter', Swal.stopTimer)
								toast.addEventListener('mouseleave', Swal.resumeTimer)
							}
						})

						Toast.fire({
							icon: 'success',
							title: 'successfully'
						})

						// let idJoborder = window.location.href.split("/").splice(0, 6)[5];
						// this.getListBuyingDownPayment(idJoborder)
						this.$nextTick(() => this.$refs.vuetable.refresh())
					} else {
						Swal.fire({
							title: "Failed!",
							text: res.data,
							icon: 'warning',
							confirmButtonColor: 'rgb(221, 107, 85)',
							confirmButtonText: 'Ok'
						})
					}
				})
				.catch((err) => {
					console.log(err.response.data)
					Swal.fire({
						title: "Failed!",
						text: err.response.data.message,
						icon: 'warning',
						confirmButtonColor: 'rgb(221, 107, 85)',
						confirmButtonText: 'Ok'
					})
				})
		},
		openModalBuying(id, idVendor, downPayment, date) {
			this.getBalanceDP(id)
				.then((res) => {
					console.log(downPayment, 'THIS DPPPP')
					console.log(this.dataBalanceDP, 'THIS DPPPP')
					this.listAutonumber = []
					this.listItem = []
					this.getListAutonumber(1)
					this.getListDocCodeNew(1)
					this.getListJobfile()

					this.getItemByPpn({
						ppn: this.dataBuying.ppn,
						itemCategory: 5345345
					})

					this.dataBuying.term = ''
					this.dataBuying.number = ''
					this.dataBuying.numberText = ''
					this.dataBuying.invoice = ''
					this.dataBuying.vendor = idVendor
					this.dataBuying.date = ''
					this.dataBuying.currency = this.dataBalanceDP.data.id_currency
					this.dataBuying.ppn = ''
					this.dataBuying.docCode = ''
					this.dataBuying.taxType = ''
					this.dataBuying.commercialRate = ''
					this.dataBuying.fiscalRate = ''
					this.dataBuying.inclusiveTax = false
					this.dataBuying.idDownPayment = id
					// this.dataBuying.downPayment = downPayment - this.dataBalanceDP.claimed
					this.dataBuying.downPayment = this.dataBalanceDP.data.down_payment - this.dataBalanceDP.claimed
					this.dataBuying.dateDownPayment = this.dateFormating(date)

					if (this.dataBuying.currency !== this.dataBalanceDP.data.id_default) {
						this.showRate = true;
					} else {
						this.showRate = false;
					}

					if(this.dataBalanceDP.data.id_wi_number !== null && this.dataBalanceDP.data.id_wi_number !== 0 && this.dataBalanceDP.data.id_wi_number !== '') {
						this.dataBuying.isWINumber = "1"
						this.dataBuying.wINumber = this.dataBalanceDP.data.id_wi_number
						this.getListWINumber(this.dataBalanceDP.data.id_wi_number)
					} else {
						this.dataBuying.isWINumber = "0"
						this.dataBuying.wINumber = ''
						this.getListWINumber()
					}

					this.itemBuying = [
						{
							item: '',
							price: '',
							qty: '',
							remarks: '',
							truck: '',
							isTruck: 0,
							idJobfile: '',
						}
					]

					let getVendor = this.listVendor.find(x => x.id === idVendor)
					this.getListTerm()
						.then((res) => {
							if (getVendor !== undefined) {
								this.dataBuying.term = parseInt(getVendor.id_term)
								this.statusModal = 'buyingCopy'
								this.$bvModal.show('modalBuyingCopy')
							}
						})
				})
		},
		addDetail(k) {
			this.itemBuying.push({
				item: '',
				price: '',
				qty: '',
				remarks: '',
				isTruck: this.itemBuying[0].isTruck,
				truck: this.itemBuying[0].truck,
				// truck: '',
				// isTruck: 0,
				idJobfile: '',
			});
		},
		removeDetail(d) {
			this.itemBuying.splice(d, 1);
		},
		submitBuyingCopy(bvModalEvent) {
			bvModalEvent.preventDefault()
			console.log(this.dataBuying, 'THIS DATA BUYINGG')
			console.log(this.itemBuying, 'THIS DATA ITEM BUYINGG')
			console.log('OKEE')

			if (this.dataBuying.number === "" && this.dataBuying.numberText === "") {
				Swal.fire({
					title: "Failed!",
					text: "Please insert field Number",
					icon: 'warning',
					// showCancelButton: true,
					confirmButtonColor: 'rgb(221, 107, 85)',
					// cancelButtonColor: '#C1C1C1',
					// reverseButtons: true,
					confirmButtonText: 'Ok'
				})

				return false;
			}

			if (this.dataBuying.vendor === "") {
				Swal.fire({
					title: "Failed!",
					text: "Please insert field Vendor",
					icon: 'warning',
					// showCancelButton: true,
					confirmButtonColor: 'rgb(221, 107, 85)',
					// cancelButtonColor: '#C1C1C1',
					// reverseButtons: true,
					confirmButtonText: 'Ok'
				})

				return false;
			}

			if (this.dataBuying.invoice === "") {
				Swal.fire({
					title: "Failed!",
					text: "Please insert field Invoice",
					icon: 'warning',
					// showCancelButton: true,
					confirmButtonColor: 'rgb(221, 107, 85)',
					// cancelButtonColor: '#C1C1C1',
					// reverseButtons: true,
					confirmButtonText: 'Ok'
				})

				return false;
			}

			if (this.dataBuying.date === "") {
				Swal.fire({
					title: "Failed!",
					text: "Please insert field Date",
					icon: 'warning',
					// showCancelButton: true,
					confirmButtonColor: 'rgb(221, 107, 85)',
					// cancelButtonColor: '#C1C1C1',
					// reverseButtons: true,
					confirmButtonText: 'Ok'
				})

				return false;
			}

			// this.dataBuying.currency = this.dataDefaultCurrency.id

			if (this.dataBuying.currency !== this.dataDefaultCurrency.id) {
				if (this.dataBuying.commercialRate === "") {
					Swal.fire({
						title: "Failed!",
						text: "Please insert field Commercial Rate",
						icon: 'warning',
						// showCancelButton: true,
						confirmButtonColor: 'rgb(221, 107, 85)',
						// cancelButtonColor: '#C1C1C1',
						// reverseButtons: true,
						confirmButtonText: 'Ok'
					})

					return false
				}
				if (this.dataBuying.fiscalRate === "") {
					Swal.fire({
						title: "Failed!",
						text: "Please insert field Fiscal Rate",
						icon: 'warning',
						// showCancelButton: true,
						confirmButtonColor: 'rgb(221, 107, 85)',
						// cancelButtonColor: '#C1C1C1',
						// reverseButtons: true,
						confirmButtonText: 'Ok'
					})

					return false
				}
			}

			let itemIsValid = true;

			this.itemBuying.forEach((dt, idx) => {
				if (dt.item === "" || dt.price === "" || dt.qty === "" || dt.idJobfile === "") {
					Swal.fire({
						title: "Failed!",
						text: "Please insert field jobfile, item, price, and qty in item row " + (idx + 1),
						icon: 'warning',
						// showCancelButton: true,
						confirmButtonColor: 'rgb(221, 107, 85)',
						// cancelButtonColor: '#C1C1C1',
						// reverseButtons: true,
						confirmButtonText: 'Ok'
					})

					itemIsValid = false;
				}
			})

			if (!itemIsValid) {
				return false;
			}

			// let idJoborder = window.location.href.split("/").splice(0, 6)[5];

			let mergeData = {
				// id_joborder: idJoborder,
				id_default: this.dataDefaultCurrency.id,
				buying: this.dataBuying,
				itemBuying: this.itemBuying
			}

			this.isLoadingForm = true

			console.log(mergeData, 'THIS MERGE DATA')

			let headers = {
				'Content-Type': 'application/json',

			}

			this.saveBuyingCopy({
				payload: mergeData,
				headers: headers
			})
				.then((res) => {
					this.isLoadingForm = false
					if (res.data.status) {
						const Toast = Swal.mixin({
							toast: true,
							position: 'bottom-end',
							showConfirmButton: false,
							timer: 2000,
							timerProgressBar: true,
							onOpen: (toast) => {
								toast.addEventListener('mouseenter', Swal.stopTimer)
								toast.addEventListener('mouseleave', Swal.resumeTimer)
							}
						})

						Toast.fire({
							icon: 'success',
							title: 'successfully'
						})

						this.$nextTick(() => {
							this.$bvModal.hide('modalBuyingCopy')
						})

						this.$nextTick(() => this.$refs.vuetable.refresh())
						// let idJoborder = window.location.href.split("/").splice(0, 6)[5];
						// this.getListBuying(idJoborder)
					} else {
						Swal.fire({
							title: "Failed!",
							text: res.data.message,
							icon: 'warning',
							confirmButtonColor: 'rgb(221, 107, 85)',
							confirmButtonText: 'Ok'
						})

						this.$nextTick(() => {
							this.$bvModal.hide('modalBuyingCopy')
						})

						this.$nextTick(() => this.$refs.vuetable.refresh())
					}
				})
				.catch((err) => {
					this.isLoadingForm = false
					Swal.fire({
						title: "Failed!",
						text: err.response.data.message,
						icon: 'warning',
						confirmButtonColor: 'rgb(221, 107, 85)',
						confirmButtonText: 'Ok'
					})
				})
		},
		onAction(action, rowData, rowIndex) {
			if (action === 'DetailPI') {
				this.$router.push({
					name: 'MultiBuyingDownPayment Detail PI',
					params: {
						id: rowData.id,
					},
				})
			} else if (action === 'detail-item') {
				this.$router.push({
					name: 'Quotation Detail',
					params: {
						id: rowData.id,
					},
				})
			} else if (action === 'view-item') {
				this.$router.push({
					name: 'Quotation View',
					params: {
						id: rowData.id,
					},
				})
			} else if (action === 'delete-item') {
				this.showConfirmDelete(rowData.id)
			} else if (action === 'view-pdf') {
				let config = {
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('access_token'),
					},
				}
				axios({
					url: this.$store.state.rootApi + '/quotation' + '/pdf/' + rowData.id,
					config,
					method: 'GET',
					responseType: 'blob',
				}).then((response) => {
					var fileURL = window.URL.createObjectURL(new Blob([response.data]))
					var fURL = document.createElement('a')

					fURL.href = fileURL
					fURL.setAttribute('download', 'invoice ' + rowData.id + '.pdf')
					document.body.appendChild(fURL)

					fURL.click()
				})
			}
		},
		isNumber: function (evt) {
			// evt = (evt) ? evt : window.event;
			var charCode = (evt.which) ? evt.which : evt.keyCode;
			if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
				evt.preventDefault();
			} else {
				return true;
			}
		},
		addDiscountPP(k) {
			this.itemDiscountPP.push({
				discountAccount: '',
				discountType: '',
				discount: 0,
			});
		},
		addDiscountPPTax(k) {
			this.itemDiscountPPTax.push({
				discountAccount: '',
				discountType: '',
				discount: 0,
			});
		},
		removeDiscountPP(d) {
			this.itemDiscountPP.splice(d, 1);
		},
		removeDiscountPPTax(d) {
			this.itemDiscountPPTax.splice(d, 1);
		},
		showDiscountAccounts(data, isTax = false) {
			if (isTax) {
				this.listDiscounts = data.discount_pp_tax

				this.$bvModal.show('modalDiscountAccounts')
			} else {
				this.listDiscounts = data.discount_pp

				this.$bvModal.show('modalDiscountAccounts')
			}
		},
		getPdf(rowData) {
			let config = {
				headers: {
					Authorization: 'Bearer ' + localStorage.getItem('access_token'),
				},
			}
			axios({
				url: this.$store.state.rootApi + '/multiBuyingDownPayment' + '/pdf/' + rowData.id,
				config,
				method: 'GET',
				responseType: 'blob',
			}).then((response) => {
				var fileURL = window.URL.createObjectURL(new Blob([response.data]))
				var fURL = document.createElement('a')

				fURL.href = fileURL
				fURL.setAttribute('download', 'Down Payment ' + rowData.number + '.pdf')
				document.body.appendChild(fURL)

				fURL.click()
			})
		},
		finishDP(id) {
			Swal.fire({
				title: 'Are you sure?',
				text: 'You will Finished this data!',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: 'rgb(221, 107, 85)',
				cancelButtonColor: '#C1C1C1',
				reverseButtons: true,
				confirmButtonText: 'Yes, finish it!',
			})
				.then((result) => {
					if (result.isConfirmed) {
						let headers = {
							'Content-Type': 'application/json'
						}

						this.finishDownPayment({
							payload: id,
							headers: headers
						})
							.then((res) => {
								console.log(res.data, 'datanya')
								if (res.status) {
									const Toast = Swal.mixin({
										toast: true,
										position: 'bottom-end',
										showConfirmButton: false,
										timer: 2000,
										timerProgressBar: true,
										onOpen: (toast) => {
											toast.addEventListener('mouseenter', Swal.stopTimer)
											toast.addEventListener('mouseleave', Swal.resumeTimer)
										}
									})

									Toast.fire({
										icon: 'success',
										title: 'successfully'
									})

									// let idJoborder = window.location.href.split("/").splice(0, 6)[5];
									// this.getListBuyingDownPayment(idJoborder)
									this.$nextTick(() => this.$refs.vuetable.refresh())
								} else {
									Swal.fire({
										title: "Failed!",
										text: res.message,
										icon: 'warning',
										confirmButtonColor: 'rgb(221, 107, 85)',
										confirmButtonText: 'Ok'
									})
								}
							})
							.catch((err) => {
								console.log(err.response.data)
								Swal.fire({
									title: "Failed!",
									text: err.response.data.message,
									icon: 'warning',
									confirmButtonColor: 'rgb(221, 107, 85)',
									confirmButtonText: 'Ok'
								})
							})
					}
				})
		},
		hideFinishedData() {
			this.hideFinishedCA = !this.hideFinishedCA

			if (this.hideFinishedCA) {
				this.apiUrl = this.$store.state.rootApi + url + '/getBuyingDownPaymentList?id_job_order=0&not_finished=1'
			} else {
				this.apiUrl = this.$store.state.rootApi + url + '/getBuyingDownPaymentList?id_job_order=0&not_finished=0'
			}

			this.$nextTick(() => this.$refs.vuetable.refresh())
		}
	},
}
</script>
<style>
.modal-xl {
	max-width: 95% !important;
}

.btn-cancel {
	/* width: 90px; */
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	height: 34px;
	background-color: #E0E0E0;
	color: #817F7F;
	border: 0;
	border-radius: 3px;
}

.btn-next {
	/* width: 90px; */
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	height: 34px;
	background-color: #51C1AD !important;
	color: #fff;
	border: 0;
	border-radius: 3px;
}

.btn-finish {
	/* width: 120px; */
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	height: 34px;
	background-color: #51C1AD;
	color: #fff;
	border: 0;
	border-radius: 3px;
}

.add-loading {
	background-color: #FFF2D9;
	border-radius: 5px;
	padding: 1em;
}

.add-unloading {
	background-color: #FFF2D9;
	border-radius: 5px;
	padding: 1em;
}

.btn-new-transaction {
	background-color: #F9A707;
	margin-right: -3.5rem;
}

.btn-new-transaction:hover {
	color: white;
}

.box {
	height: 200px;
	width: 100%;
	background-color: white;
	margin: 0 auto;
	top: 200px;
	text-align: center;
	align-content: center;
	margin-top: 120px;
}

.image-truck {
	width: 60px;
	margin-left: 35px;
}

.wizard-icon-container i {
	font-style: normal;
}

.wizard-icon-circle i {
	font-style: normal;
}

/* .wizard-icon-circle.checked {
    color: rgb(249, 167, 7);
  } */

.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
	border-color: #f1556c;
}

.vs__dropdown-option {
	white-space: inherit !important;
}

.__style-dete-required {
	border: 1px solid #f1556c;
	border-radius: 4px;
}

#modalBuyingDownPayment___BV_modal_footer_ {
	display: none;
}

.btn-action {
	width: 25px;
	height: 25px;
	padding: 0;
}

.table-button-container {
	min-width: 150px;
}

.pagination .btn {
	color: #000 !important;
}

.pagination .btn.text-white {
	color: #FFF !important;
}

#modalBuying___BV_modal_body_ {
	padding: 0;
}

.information-dp {
	width: 100%;
	height: 50px;
	background: #E2F4F1;
	display: flex;
	align-items: center;
	padding-left: 10px;
}

.new-card .card-body {
	padding-left: 10px;
	padding-right: 10px;
}

.form-control:disabled,
.form-control[readonly] {
	background-color: var(--vs-disabled-bg);
	opacity: 1;
}

.dropdown-toggle {
	white-space: nowrap;
	padding: 0;
}
</style>
